<template>
  <div :id="id" class="time-counter">
    <div class="time">
      {{ timeTotal }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'TimerCountdownText',
  props: {
    id: {
      type: String,
      default: 'pnlTimerCountdownText',
    },
    title: {
      type: String,
      default: '',
    },
    startTimeSeconds: {
      type: Number,
      default: 0,
    },
    onCountdownFinish: {
      type: Function,
      default: () => {},
    },
    isCountdown: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      baseTimeSeconds: this.startTimeSeconds,
      timeCounterInterval: null,
    };
  },
  mounted() {
    this.baseTimeSeconds = this.startTimeSeconds;

    this.timeCounterInterval = setInterval(() => {
      if (!this.isCountdown) {
        this.baseTimeSeconds += 1;
      } else {
        this.baseTimeSeconds -= 1;

        if (this.baseTimeSeconds == 0) {
          clearInterval(this.timeCounterInterval);
          this.onCountdownFinish();
        }
      }
    }, 1000);
  },
  computed: {
    timeTotal() {
      return this.secondsToTime(this.baseTimeSeconds);
    },
  },
  methods: {
    secondsToTime(secs) {
      secs = Math.round(secs);
      var hours = Math.floor(secs / (60 * 60))
        .toString()
        .padStart(2, '0');

      var divisor_for_minutes = secs % (60 * 60);
      var minutes = Math.floor(divisor_for_minutes / 60)
        .toString()
        .padStart(2, '0');

      var divisor_for_seconds = divisor_for_minutes % 60;
      var seconds = Math.ceil(divisor_for_seconds).toString().padStart(2, '0');

      var obj = {
        h: hours,
        m: minutes,
        s: seconds,
      };
      return `${obj.h}:${obj.m}:${obj.s}`;
    },
  },
  beforeDestroy() {
    clearInterval(this.timeCounterInterval);
  },
};
</script>

<style lang="scss" scoped>
.time-counter {
  color: #7d4a08;
  font-size: 20px;
  font-weight: bold;
  padding: 0 4px;
  text-align: center;
}

.time-counter .time {
  font-size: 30px;
}

@media only screen and (max-width: 768px) {
  .time-counter {
    width: 100%;
    bottom: 0px;
    left: 0px;
    right: 0px;
    padding: 5px 0;
    border-radius: 0;
  }
}
</style>