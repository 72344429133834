import Swal from 'sweetalert2';
import { modulesName } from '@/store';
import { userAcceptWaitingRoom as _userAcceptWaitingRoom } from '@/services/WaitingRoomService';
import { specialistAcceptWaitingRoom as _specialistAcceptWaitingRoom } from '@/services/WaitingRoomService';
import ParticipantType from '@/constants/ParticipantType';

import { COMMUNICATION } from '@/config';

export const roomEntryRequestMixin = {
  methods: {
    async showRoomEntryRequest(waitingRoomId, userId, participantType) {
      const swal = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success mt-3 mr-2',
          cancelButton: 'btn btn-danger mt-3',
        },
        buttonsStyling: false,
      });

      let type = '';
      switch (participantType) {
        case ParticipantType.Monitor:
          type = this.$t('monitor');
          break;
        case ParticipantType.Supervisor:
          type = this.$t('supervisor');
          break;

        default:
          type = this.$t('passive');
          break;
      }
      await this.$turnHub.$emit('joinWaitingRoomGroup', waitingRoomId);
      this.$turnHub.$off('waitingRoomFinished');
      this.$turnHub.$on('waitingRoomFinished', () => {
        swal.close();
      });
      swal
        .fire({
          toast: true,
          position: 'top-end',
          title: this.$t('requestToJoinWaitingRoom'),
          html: this.$t('YouHaveReceivedRequestToJoinWaitingRoom', { type: type.toLowerCase() }),
          timer: participantType == ParticipantType.Passive ? COMMUNICATION.PASSIVEUSER_ALERT_TIMEOUT : COMMUNICATION.SPECILISTEUSER_ALERT_TIMEOUT,
          timerProgressBar: true,
          showCancelButton: true,
          confirmButtonText: this.$t('join'),
          cancelButtonText: this.$t('decline'),
          showLoaderOnConfirm: true,
          preConfirm: async () => {
            swal.stopTimer();
            await _userAcceptWaitingRoom({
              userId,
              waitingRoomId,
              participantType
            })
            return true;
          },
          didOpen: () => {},
          willClose: () => {},
        })
        .then(async (result) => {
          if (result.dismiss === Swal.DismissReason.timer) {
            this.$turnHub.$emit(
              'userNoAcceptedWaitingRoom',
              waitingRoomId,
              participantType,
            );
          }

          if (result.isConfirmed) {
            if (this.$route.name === 'turnsManager') {
              this.$store.dispatch(
                `${modulesName.turnManagerModuleName}/refreshTurnManager`,
                null,
                { root: true },
              );
            } else {
              this.$router.push({ name: 'turnsManager' });
            }
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            this.$turnHub.$emit(
              'userNoAcceptedWaitingRoom',
              waitingRoomId,
              participantType,
            );
          }
        });
    },
    showSpecialistRoomEntryRequest(waitingRoomId, userId) {
      const swal = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success mt-3 mr-2',
          cancelButton: 'btn btn-danger mt-3',
        },
        buttonsStyling: false,
      });

      swal
        .fire({
          toast: true,
          position: 'top-end',
          title: this.$t('requestToAttendTurn'),
          html: this.$t('youhaveRecievedRequestAsSpecialist'),
          timer: COMMUNICATION.SPECILISTEUSER_ALERT_TIMEOUT,
          timerProgressBar: true,
          showCancelButton: true,
          confirmButtonText: this.$t('join'),
          cancelButtonText: this.$t('decline'),
          showLoaderOnConfirm: true,
          preConfirm: async () => {
            swal.stopTimer();
            await _specialistAcceptWaitingRoom({
              userId,
              waitingRoomId,
            })
            return true;
          },
          didOpen: () => {},
          willClose: () => {},
        })
        .then(async (result) => {
          if (result.dismiss === Swal.DismissReason.timer) {
            this.$turnHub.$emit(
              'specialistNoAcceptedWaitingRoom',
              waitingRoomId,
            );
          }

          if (result.isConfirmed) {
            if (this.$route.name === 'turnsManager') {
              this.$store.dispatch(
                `${modulesName.turnManagerModuleName}/refreshTurnManager`,
                null,
                { root: true },
              );
            } else {
              this.$router.push({ name: 'turnsManager' });
            }
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            this.$turnHub.$emit(
              'specialistNoAcceptedWaitingRoom',
              waitingRoomId
            );
          }
        });
    }
  },
};
