<template>
  <div class="col-md-12 my-2">
    <div class="card" style="border: white">
      <div class="row">
        <div class="col-md-12">
          <div
            class="header p-2 py-3"
            @click="onSelect()"
          >
            <i class="fal fa-plus-circle mr-2 ml-3"></i>
            {{ title }}
            
          </div>
          
        </div>
        <div
      class="col-md-12"
      :class="{'mb-2': isOpen}"
    >
      <transition
        name="slide"
      >
        <div
          v-if="isOpen"
          class="content px-2"
        >
        <hr class="mx-2" style="border: 1px solid #F4F4F4; margin-top: -10px">
          <div class="row">
            <div class="col-md-12 px-4 pb-4">
              <div class="row">
                <slot />
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Collapse',
  props: {
    children: {
      type: Boolean,
      default: () => false,
    },
    title: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  watch: {
    value(val) {
      this.isOpen = val;
    },
  },
  methods: {
    onSelect() {
      this.isOpen = !this.isOpen;
      this.$emit('input', this.isOpen);
    },
  },
};
</script>


<style lang="scss" scoped>

.header {
  font-size: 16px;
  color: $color-primary;
  cursor: pointer;
  i {
    color: #707070;
  }
}

.slide-enter-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

.slide-leave-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to,
.slide-leave {
  max-height: 500px;
  overflow: hidden;
}

.slide-enter,
.slide-leave-to {
  overflow: hidden;
  max-height: 0;
}
</style>

