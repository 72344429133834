<template>
  <div class="input-group cus-numberinput-container">
    <vue-numeric-input
      v-model="selectedValue"
      :class="[iconClass != '' ? 'hasIcon' : '']"
      :controls="false"
      :max="max"
      :min="min"
      :step="step"
    />
    <b-icon
      class="custom-form-input-icon"
      :icon="iconClass"
    />
    <div class="input-group-append">
      <span class="input-group-text">
        <i
          class="fal fa-angle-up"
          @click="incrementValue"
        />
        <span />
        <i
          class="fal fa-angle-down"
          @click="decrementValue"
        /></span>
    </div>
  </div>
</template>
<script>
import VueNumericInput from 'vue-numeric-input';
export default {
  name: 'BaseNumericInput',
  components: {
    VueNumericInput
  },
  data () {
    return {
      selectedValue: this.value
    };
  },
  mounted () {
    this.selectedValue = this.value;
  },
  watch: {
    value: function (value) {
      this.selectedValue = value;
    }
  },
  methods: {
    incrementValue () {
      this.selectedValue++;
      this.$emit('on-selected-value', this.selectedValue);
    },
    decrementValue () {
      this.selectedValue--;
      this.$emit('on-selected-value', this.selectedValue);
    }
  },
  props: {
    iconClass: {
      type: String,
      default: ''
    },
    value: {
      type: Number,
      required: true,
      default: 0
    },
    min: {
      type: Number,
      default: 0
    },
    step: {
      type: Number,
      default: 1
    },
    max: {
      type: Number,
      default: 999
    }
  }
};
</script>
<style lang="scss">
.cus-numberinput-container {
  // width: 69px;
  .input-group-text {
    padding: 0px;
    background-color: $color-white;
    display: block;
    padding: 2px 0px 0px 0px;
    background-color: $color-white;
    width: 24px;
  }
  .input-group-text > i {
    display: block;
    height: 16px;
    cursor: pointer;
  }

  .input-group-text > span {
    width: 100%;
    height: 1px;
    border: 1px solid $color-label-default;
    display: block;
    border-radius: 4px;
    border: 1px solid $color-label-default;
    border-radius: 4px;
    opacity: 0.7;
  }

  .cus-numberinput-container input[type='number']::-webkit-outer-spin-button,
  .cus-numberinput-container input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0;
  }

  /* Firefox */
  .cus-numberinput-container input[type='number'] {
    -moz-appearance: textfield !important;
  }

  .vue-numeric-input.hasIcon .numeric-input {
    text-align: left !important;
    padding-left: 36px !important;
  }

  .vue-numeric-input .numeric-input {
    -moz-appearance: textfield !important;
    -webkit-appearance: none !important;
    margin: 0;

    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: $color-font-secondary;
    background-color: $color-white;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .vue-numeric-input {
    width: 70% !important;
  }

  .input-group-append {
    width: 30% !important;
  }
}
</style>
