import { apiTurnos } from '@/core/api/';

export const getStationList = (locationConfigurationId) => apiTurnos.get(`/api/station/${locationConfigurationId}/list`);

export const getStations = (payload) => apiTurnos.get('/api/station/search',{ params : { ...payload }});

export const deleteStation = (payload) => apiTurnos.delete(`/api/station/${payload}`);

export const addStation = (payload) => apiTurnos.post('/api/station', payload);

export const updateStation = (payload) => apiTurnos.put(`/api/station/${payload.id}`, payload);

export const getStationById = (id) => apiTurnos.get(`/api/station/${id}`);

export const getPermissionsList = (payload) => apiTurnos.post(`api/station/permissions/list`, payload);

export const getRolePermissionsList = (payload) => apiTurnos.post(`api/station/role/permissions/list`, payload);

export const addPermissions = (payload) => apiTurnos.post(`api/station/${payload.id}/permissions`, payload.permissions);

export const updateStationRole = (payload) => apiTurnos.post(`api/station/${payload.id}/role`, payload.roleId || '00000000-0000-0000-0000-000000000000');

export const getPermissionsListById = (stationId) => apiTurnos.get(`api/station/${stationId}/permissions/list`);
