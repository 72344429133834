<template>
  <section class="chat-manager">
    <div class="main-header">
      <div class="row mx-0 h-100">
        <template v-if="currentRoom">
          <div class="col-md-5 d-flex flex-column justify-content-center">
            <div class="d-flex">
              <span class="info mr-1">{{ $t('turnNumber') }}:</span>
              <span class="info-value">{{ currentRoom.turnNumber }} </span>
            </div>
            <div class="d-flex">
              <span class="info mr-1">{{ $t('queue') }}:</span>
              <span class="info-value">
                {{ currentRoom.serviceQueueName }}
              </span>
            </div>
          </div>
          <div class="col-md-7 d-flex align-items-center">
            <span class="info mr-1">{{ $t('location') }}:</span>
            <span class="info-value">{{ currentRoom.locationName }}</span>
          </div>
        </template>

        <i class="far fa-times-circle" @click="$emit('close')"></i>
      </div>
    </div>
    <div class="main-content" :class="{ 'pt-5': rocketChat.loadingRooms }">
      <Loader class="mt-5" v-if="rocketChat.loadingRooms" />
      <ChatSidebar
        v-if="!rocketChat.loadingRooms"
        :current-room-id="rocketChat.currentRoomId"
        :rooms="rocketChat.rooms"
        @room-selected="onRoomSelected($event)"
      />
      <Chat :room="currentRoom" v-if="currentRoom" />
    </div>
  </section>
</template>

<script>
import ChatSidebar from './components/Sidebar.vue';
import Chat from './components/Chat.vue';
import RocketChatService from '@/services/RocketChatService';
import Loader from '@/components/Loader';

export default {
  name: 'ChatManager',
  components: {
    ChatSidebar,
    Chat,
    Loader,
  },
  data() {
    return {
      rocketChat: RocketChatService,
    };
  },
  async mounted() {
    this.rocketChat.initilize();
  },
  computed: {
    currentRoom() {
      return this.rocketChat.rooms.find(
        (x) => x.id == this.rocketChat.currentRoomId,
      );
    },
  },
  methods: {
    onRoomSelected(chatId) {
      this.rocketChat.currentRoomId = chatId;
      this.currentRoom.unread = false;
      this.currentRoom.mention = false;
    },
  },
};
</script>

<style scoped lang="scss">
.chat-manager {
  .main-header {
    height: 70px;
    background-color: #fff;
    box-shadow: 2px 4px 20px #e6e6e6;
    // border-bottom: 2px solid #FAFAFA;

    .info {
      color: #1c1d21;
      font-size: 16px;
      font-weight: bold;
    }
    .info-value {
      color: #ff8105;
      font-size: 16px;
      font-weight: bold;
    }
    i {
      position: absolute;
      right: 6px;
      top: 6px;
      font-size: 26px;
      cursor: pointer;
    }
  }
}
</style>
