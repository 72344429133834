<template>
  <b-navbar toggleable="lg" type="none" variant="" vertical>
    <b-navbar-nav class="mr-xl-auto burger-container">
      <b-nav-item class="c-hamburgerIcon" href="#">
        <div
          v-b-toggle.sidebar-1
          class="nav-item-icon-square"
          @click="handleTogglerClick"
        >
          <i class="fa fa-bars text-middle" />
        </div>
      </b-nav-item>
    </b-navbar-nav>
    <b-navbar-nav class="d-sm-none" style="width: 80%">
      <b-nav-item
        class="nav-item-text-content text-right float-right"
        href="#"
        @click="logout"
      >
        <div class="float-right">
          <div class="nav-item-text-content-sub-header mr-2 float-left">
            {{ $t('logout') }}
          </div>
          <div class="custom-dropdown-toggle float-left">
            <i class="fal fa-sign-out-alt text-primary" />
          </div>
        </div>
      </b-nav-item>
    </b-navbar-nav>
    <b-navbar-nav class="navbar-nav">
      <b-nav-item class="nav-item-text-content">
        <WebPhone />
      </b-nav-item>
    </b-navbar-nav>
    <b-navbar-nav
      v-if="false"
      class="navbar-nav d-none d-sm-block"
      @click="showChatSidebar = true"
    >
      <b-nav-item class="nav-item-text-content">
        <div
          class="alert-notification"
          :class="{
            unread: showIntenalChatUnreadAlert && !showIntenalChatMentionAlert,
            mention: showIntenalChatMentionAlert,
          }"
        />
        <div class="nav-item-icon chat-icon special mr-2 float-left">
          <i class="fal fa-comment-alt-lines text-middle" />
        </div>
      </b-nav-item>
    </b-navbar-nav>
    <b-navbar-nav class="navbar-nav mr-lg-5">
      <b-nav-item id="-selectOffice" class="nav-item-text-content" href="#">
        <div class="d-flex">
          <div class="nav-item-icon special mr-2">
            <i class="far fa-text-size" />
          </div>
          <div class="c-fontSize">
            <div class="title">
              {{ $t('fontSize') }}
            </div>
            <div class="sizes d-flex align-items-end">
              <button
                class="btn text-uppercase size py-0 size-16"
                :class="{ active: bodyZoom == '100%' }"
                type="button"
                @click="toggleZoomScreen(100)"
              >
                a
              </button>
              <button
                class="btn text-uppercase size py-0 size-20"
                :class="{ active: bodyZoom == '125%' }"
                type="button"
                @click="toggleZoomScreen(125)"
              >
                a
              </button>
              <button
                class="btn text-uppercase size py-0 size-24 d-none d-md-block"
                :class="{ active: bodyZoom == '150%' }"
                type="button"
                @click="toggleZoomScreen(150)"
              >
                a
              </button>
            </div>
          </div>
          <div class="custom-dropdown-toggle d-none d-sm-block float-left" />
        </div>
      </b-nav-item>
    </b-navbar-nav>
    <b-navbar-nav v-if="isMultiOffice" class="navbar-nav mr-lg-5">
      <b-nav-item id="-selectOffice" class="nav-item-text-content" href="#">
        <div class="nav-item-icon special mr-2 float-left">
          <i class="fas fa-dewpoint text-dark text-middle" />
        </div>
        <div class="float-left">
          <div class="nav-item-text-content-header">
            <b-dropdown
              class="c-locationDropdown"
              :class="{ 'display-locations': !selectedLocation }"
              :text="$t('office')"
              variant="outline"
            >
              <b-dropdown-item
                v-for="(location, index) in currentLocations"
                :key="index"
                class="pt-0"
                :style="{
                  'background-color':
                    selectedLocation &&
                    selectedLocation.locationId === location.locationId
                      ? '#f8f9fa'
                      : '',
                }"
                :value="index"
                @click="onLocationChange(location)"
              >
                <i class="fal fa-chevron-circle-right text-primary" />
                <span>{{ location.name }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>

          <div class="nav-item-text-content-sub-header">
            {{ selectedLocation ? selectedLocation.name : $t('office') }}
          </div>
        </div>
        <div class="custom-dropdown-toggle d-none d-sm-block float-left" />
      </b-nav-item>
    </b-navbar-nav>
    <b-navbar-nav
      v-if="isMultiOffice && isInternalChatActive"
      class="navbar-nav d-sm-none"
      @click="showChatSidebar = true"
    >
      <b-nav-item class="nav-item-text-content">
        <div class="nav-item-icon chat-icon special mr-2 float-left">
          <i class="fal fa-comment-alt-lines text-middle" />
        </div>
      </b-nav-item>
    </b-navbar-nav>
    <b-navbar-nav
      class="mr-lg-5"
      :class="{ disabled: !selectedLocation && isMultiOffice }"
    >
      <b-nav-item
        id="-selectProfile"
        class="nav-item-text-content"
        href="#"
        @click="() => (profileSelected = !profileSelected)"
      >
        <div class="nav-item-icon special mr-2 float-left">
          <i class="fas fa-user-tie text-dark text-middle" />
        </div>
        <div
          class="custom-dropdown-toggle d-sm-block float-left"
          :class="{ 'd-none': !profileSelected }"
        >
          <div class="nav-item-text-content-header">
            <b-dropdown
              class="c-profileDropdown"
              :text="userProfile.fullName"
              variant="outline"
            >
              <b-dropdown-item>
                <router-link
                  class="text-primary"
                  :event="'click'"
                  :to="profileNavPath"
                >
                  <i class="fal fa-user-alt text-primary" />{{
                    $t('myprofile')
                  }}
                </router-link>
              </b-dropdown-item>
              <b-dropdown-item @click="showChangePassModal = true">
                <i class="fal fa-lock text-primary" />
                {{ $t('changepassword') }}
              </b-dropdown-item>
              <b-dropdown-item
                class="nav-item-text-content-sub-header c-lastLoginText mr-2"
              >
                <span class="bold">{{ $t('lastlogin') }}: </span>
                {{ userProfile.lastLoginDate }}
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <div class="nav-item-text-content-sub-header">
            {{ userProfile.roleDescription }}
          </div>
        </div>
        <div class="custom-dropdown-toggle d-none d-sm-block float-left" />
      </b-nav-item>
    </b-navbar-nav>
    <b-navbar-nav
      id="lang3"
      class="d-none d-sm-block"
      :class="{ disabled: !selectedLocation && isMultiOffice }"
    >
      <b-nav-item class="nav-item-text-content" href="#">
        <div class="nav-item-text-content-sub-header mr-2 float-left">
          <i class="fal fa-globe" />
          <b-dropdown
            class="c-selectedLanguageDropdown"
            :text="languageName"
            variant="outline"
          >
            <b-dropdown-item
              v-for="lang in languages"
              :key="lang.value"
              class="pt-0"
              href="#"
              @click="changeLanguage(lang.value)"
            >
              {{ lang.text }}
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </b-nav-item>
    </b-navbar-nav>
    <b-navbar-nav class="d-none d-sm-block">
      <b-nav-item class="nav-item-text-content" href="#" @click="logout">
        <div class="nav-item-text-content-sub-header mr-2 float-left">
          {{ $t('logout') }}
        </div>
        <div class="custom-dropdown-toggle float-left">
          <i class="fal fa-sign-out-alt text-primary mt-0" />
        </div>
      </b-nav-item>
    </b-navbar-nav>
    <custom-modal
      :title="$t('changepassword')"
      v-model="showChangePassModal"
      size="md"
    >
      <change-password @close="showChangePassModal = false" />
    </custom-modal>
    <custom-modal
      :title="$t('selectOneStation')"
      v-model="showStationModal"
      :noCloseOnBackDrop="true"
      :hideHeaderClose="true"
      size="md"
    >
      <StationList
        :location="selectedLocation"
        @close="showStationModal = false"
      />
    </custom-modal>
    <b-sidebar
      v-if="false"
      :visible="showChatSidebar"
      sidebar-class="main-chat-sidebar"
      width="720px"
      no-header
      backdrop
      shadow
      @change="showChatChange($event)"
    >
      <ChatManager @close="showChatChange(false)" />
    </b-sidebar>
  </b-navbar>
</template>

<script>
import { logoutWithRedirect } from '../../core/auth';
import { mapGetters, mapState } from 'vuex';

import { clearApiCache } from '@/modules/user/api';
import IdentityPermission from '@/constants/IdentityPermission';
import { modulesName } from '@/store';
import {
  login as _loginBreak,
  logout as _logoutBreak,
} from '@/services/BreakManagerService';
import { getCanAskForStation as _getCanAskForStation } from '@/services/LocationService';
import { isInternalChatActive as _isInternalChatActive } from '@/services/UserService';
import CustomModal from '../basics/modal/CustomModal.vue';
import ChangePassword from '../ChangePassword.vue';
import StationList from '../StationList.vue';
import SystemRoles from '@/constants/SystemRoles';
import ChatManager from '../chat/Manager';
import RocketChatService from '@/services/RocketChatService';
import WebPhone from '../webphone/WebPhone.vue';

export default {
  components: {
    ChangePassword,
    StationList,
    CustomModal,
    ChatManager,
    WebPhone,
  },
  name: 'TopNavbar',
  data() {
    return {
      userInfo: [],
      locations: [],
      selectedLocation: null,
      turn: [],
      language:
        this.$store.getters[`${modulesName.languageModuleName}/getLanguage`],
      languages: [
        { text: 'Español (Puerto Rico)', value: 'es' },
        { text: 'English (United States)', value: 'en' },
      ],
      profileSelected: false,
      bodyZoom: '100%',
      profileNavPath: '/user/profile',
      showChangePassModal: false,
      showStationModal: false,
      showChatSidebar: false,
      rocketChat: RocketChatService,
    };
  },
  created() {
    this.$i18n.locale = this.language;
  },
  mounted() {
    this.$store.subscribeAction((action) => {
      if (
        action.type === `${modulesName.userModuleName}/setCurrentActiveLocation`
      ) {
        this.onLocationChange(action.payload);
      }
    });
  },
  computed: {
    ...mapGetters(modulesName.userModuleName, [
      'fullProfile',
      'currentActiveLocation',
      'currentLocations',
      'isInternalChatActive',
    ]),
    ...mapState(modulesName.turnManagerModuleName, ['activeTurn']),
    ...mapState(modulesName.userModuleName, [
      'activeBreakAndTurn',
      'currentActiveStation',
      'profile',
    ]),
    languageName() {
      return this.languages.find((x) => x.value === this.language).text;
    },
    userProfile() {
      if (this.fullProfile) {
        return this.fullProfile;
      } else {
        return {
          fullName: '',
          roleDescription: '',
          lastLoginDate: '',
          locations: [],
        };
      }
    },
    isMultiOffice() {
      return this.userHasPermissions(IdentityPermission.multi.office);
    },
    askForStationPermission() {
      return this.userHasPermissions(IdentityPermission.multi.station.ask);
    },
    showIntenalChatMentionAlert() {
      return this.rocketChat.rooms.some((x) => x.mention);
    },
    showIntenalChatUnreadAlert() {
      return this.rocketChat.rooms.some((x) => x.unread);
    },
  },
  watch: {
    currentActiveLocation(value) {
      if (
        this.selectedLocation &&
        value &&
        this.selectedLocation.locationConfigurationId ==
          value.locationConfigurationId
      )
        return;
      this.selectedLocation = { ...value };
    },
    showChatSidebar(value) {
      this.$store.dispatch(
        `${modulesName.userModuleName}/showInternalChat`,
        value,
      );
    },
  },
  methods: {
    showChatChange(value) {
      this.showChatSidebar = value;
      if (!value) {
        this.rocketChat.currentRoomId = 0;
      }
    },
    async checkInternalChatActive() {
      if (
        !this.profile.userId ||
        !this.selectedLocation ||
        !this.selectedLocation.locationConfigurationId
      )
        return;
      const { data } = await _isInternalChatActive({
        userId: this.profile.userId,
        locationConfigurationId: this.selectedLocation.locationConfigurationId,
      });
      if (data) {
        await RocketChatService.login();
      }
      this.$store.dispatch(
        `${modulesName.userModuleName}/setInternalChatActive`,
        data,
      );
    },
    toggleZoomScreen(size) {
      this.bodyZoom = `${size}%`;
      document.body.style.zoom = this.bodyZoom;
    },
    handleTogglerClick() {
      this.$emit('toggledSidebar');
    },
    changeLanguage(value) {
      this.$i18n.locale = value;
      this.$moment.locale(value.replace('"', /'/g));
      this.language = value;
      this.$store.dispatch(
        `${modulesName.languageModuleName}/setLanguage`,
        value,
      );
    },
    onLocationChange(location) {
      if (!location) return (this.selectedLocation = null);

      if (
        this.selectedLocation &&
        this.selectedLocation.locationId === location.locationId
      )
        return;

      var activeTurnManager =
        this.activeTurn &&
        !this.activeTurn.finalized &&
        this.activeTurn.locationConfigurationId ==
          this.currentActiveLocation?.locationConfigurationId;

      var activeTurnUser =
        this.activeBreakAndTurn.activeTurn &&
        this.activeBreakAndTurn.activeTurn.locationConfigurationId ==
          this.currentActiveLocation?.locationConfigurationId;

      if (this.currentActiveLocation && (activeTurnManager || activeTurnUser)) {
        return this.ShowToast(
          'Error',
          this.$t('cantChangeLocationActiveTurn'),
          'error',
        );
      }

      var activeWaitingRoom =
        this.activeBreakAndTurn.activeWaitingRoom &&
        this.activeBreakAndTurn.activeWaitingRoom.locationConfigurationId ==
          this.currentActiveLocation?.locationConfigurationId;

      if (this.currentActiveLocation && activeWaitingRoom) {
        return this.ShowToast(
          'Error',
          this.$t('cantChangeLocationActiveWaitingRoom'),
          'error',
        );
      }

      this.$store.dispatch(`${modulesName.appModuleName}/setLoading`, true);
      const lastLocation = this.selectedLocation;
      this.selectedLocation = null;
      _loginBreak(location.locationConfigurationId)
        .then((response) => {
          if (!response.data) {
            this.selectedLocation = lastLocation;
            return this.ShowToast(
              'Error',
              this.$t('errorLogginIntoOffice'),
              'error',
            );
          }

          this.$store.dispatch(
            `${modulesName.userModuleName}/setCurrentActiveStation`,
            {
              id: null,
              locationConfigurationId: null,
              serviceQueueId: null,
              fixedServiceQueue: null,
              permissions: [],
            },
          );

          this.selectedLocation = { ...location };
          this.checkInternalChatActive();
          this.askForStation();
        })
        .catch(() => {
          this.ShowToast('Error', this.$t('errorLogginIntoOffice'), 'error');
          this.selectedLocation = lastLocation;
        });
    },
    async askForStation() {
      await _getCanAskForStation(
        this.selectedLocation?.locationConfigurationId,
      ).then((response) => {
        this.showStationModal = response.data;
        if (
          !this.showStationModal ||
          (this.profile.roleId !== SystemRoles.clientuser &&
            this.profile.roleId !== SystemRoles.clientlist)
        ) {
          this.showStationModal = false;
          this.$store.dispatch(
            `${modulesName.userModuleName}/setCurrentActiveLocation`,
            this.selectedLocation,
          );
        }
      });
    },
    async logout() {
      const turnActiveAndNotFinalized =
        this.activeTurn && !this.activeTurn.finalized;
      const activeTurn =
        this.activeBreakAndTurn && this.activeBreakAndTurn.activeTurn;
      const activeWaitingRoom =
        this.activeBreakAndTurn && this.activeBreakAndTurn.activeWaitingRoom;

      if (turnActiveAndNotFinalized || activeTurn || activeWaitingRoom) {
        return this.ShowToast(
          'Error',
          this.$t('youMustEndTurnToLogOut'),
          'error',
        );
      }

      if (
        (!this.activeBreakAndTurn || !this.activeBreakAndTurn.activeBreak) &&
        this.currentActiveLocation &&
        this.currentActiveLocation.locationConfigurationId
      ) {
        await _logoutBreak(this.currentActiveLocation.locationConfigurationId);
      }
      await clearApiCache();
      logoutWithRedirect();
    },
  },
  updated() {
    if (this.currentLocations.length === 1) {
      this.locationConfigurationId =
        this.currentLocations[0].LocationConfigurationId;
      this.locationName = this.currentLocations[0].LocationName;
    }
  },
};
</script>
<style lang="scss">
.navbar {
  min-height: 68px;
  background: $color-white 0% 0% no-repeat padding-box;
  opacity: 1;

  .disabled {
    cursor: not-allowed;
    pointer-events: none;
  }

  .btn-group {
    vertical-align: baseline !important;
  }

  @media only screen and (min-device-width: 768px) {
    .expandable-navbar {
      width: 40%;
    }
  }

  @media only screen and (min-device-width: 1025px) {
    .expandable-navbar {
      width: 20%;
    }
  }
  .c-hamburgerIcon {
    @media only screen and (min-width: 768px) {
      display: none;
    }
  }
  .c-fontSize {
    .title {
      color: #212529;
    }
    .sizes {
      margin-top: 0.3rem;
      .size {
        line-height: 18px;
        color: #ff8105;
        &-16 {
          font-size: 16px;
        }
        &-20 {
          font-size: 20px;
        }
        &-24 {
          font-size: 24px;
        }
      }
    }
    .active {
      color: white !important;
      background-color: #ff8105 !important;
    }
  }

  .nav-item-text-content {
    text-align: left;
    font: normal normal bold 16px/19px Lato;
    letter-spacing: 0px;
    opacity: 1;
    position: relative;

    .nav-link {
      padding: 0 !important;
    }
    .alert-notification {
      position: absolute;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      right: 4px;
      top: -8px;
      &.unread {
        background-color: #ff8106;
      }
      &.mention {
        background-color: #fb2a2a;
      }
    }
    .chat-icon {
      border: 1px solid #ff8105;
      i {
        color: #ff8105;
      }
    }

    .nav-item-text-content-header {
      color: $color-font-primary !important;

      .dropdown.b-dropdown.show.btn-group {
        .dropdown-menu.show {
          position: absolute;
          display: flex !important;
        }
      }

      .c-locationDropdown {
        &.display-locations {
          .dropdown-menu {
            display: block;
            position: absolute;
            display: flex !important;
          }
        }
        button.dropdown-toggle {
          display: inline-flex;
          align-items: center;
        }
        ul.dropdown-menu {
          width: 48vw;
          //display: flex;
          // display: block;
          flex-wrap: wrap;
          margin-top: 28px;
          left: calc((48vw / 2) - (100vw / 2));

          @media only screen and (max-width: 1200px) {
            left: 0;
          }

          @media only screen and (max-width: 500px) {
            width: 80vw;
          }
          li {
            width: 33%;
            font-family: 'lato-medium', sans-serif;
            display: flex;
            align-items: center;
            @media only screen and (max-width: 1025px) {
              width: 50%;
            }

            @media only screen and (max-width: 927px) {
              width: 100%;
            }

            i {
              font-size: 14px;
              margin-right: 18px;
            }

            a.dropdown-item {
              white-space: normal;
              color: #ff8106 !important;
              display: flex;
              align-items: center;
              span {
                text-decoration: underline;
                word-break: break-all;
              }
            }
          }
        }
      }
      .c-profileDropdown {
        button.dropdown-toggle {
          display: inline-flex;
          align-items: center;
        }
        ul.dropdown-menu {
          flex-direction: column;
          padding: 15px;
          margin-top: 28px;

          @media only screen and (max-width: 635px) {
            left: unset;
            right: 0;
          }

          li {
            i {
              font-size: 20px;
              margin-right: 12px;
            }
            a.dropdown-item {
              padding-left: 0;
              padding-right: 0;
              color: #ff8106;
              text-decoration: underline;
            }

            &.c-lastLoginText {
              margin-top: 10px;
              padding-top: 10px;
              border-top: 1px solid $color-label-default;

              a.dropdown-item {
                color: #3f5b60;
                text-decoration: none;
              }

              span.bold {
                font-family: 'lato-bold', sans-serif;
              }
            }
          }
        }
      }
      .dropdown-toggle {
        padding-top: 0px;
        padding-left: 0px;
        padding-bottom: 0px;
        font-family: Lato;
        font-size: 16px;
        font-weight: bold;
        margin-top: -4px;
        border: 0px;
      }

      .dropdown-toggle::after {
        content: '';
        -webkit-mask-image: url('../../assets/icon_arrowBlackDown.svg');
        background-color: #000000;
        -webkit-mask-size: 14px 9px;
        -webkit-mask-repeat: no-repeat;
        width: 14px;
        height: 9px;
        margin-left: 10px;

        border-top: unset !important;
        border-right: unset !important;
        border-bottom: unset !important;
        border-left: unset !important;
      }
    }

    .c-selectedLanguageDropdown {
      button {
        font-weight: bold;
        color: #ff8106;
      }
      ul.dropdown-menu {
        padding: 10px;
        margin-top: 10px;
        li {
          text-decoration: underline;

          a.dropdown-item {
            color: #ff8106;
            padding: 10px 0;
          }
          &:not(:first-child) {
            border-top: 1px solid $color-app-background;
          }
        }
      }
    }
    .nav-item-text-content-sub-header {
      color: $color-primary !important;
    }

    .sub-header-reduced,
    .header-reduced {
      margin-top: -5px;
    }
  }

  .custom-dropdown-toggle {
    i {
      font-size: 21px;
      color: $color-font-primary;
      margin-top: -5px;
      font-weight: 400;
    }
  }

  .nav-item-icon {
    text-align: center;
    width: 40px;
    height: 40px;
    border: 1px solid $color-primary;
    border-radius: 50%;

    i {
      vertical-align: -webkit-baseline-middle;
      font-size: 23px;
      color: $color-primary;
    }
  }

  .nav-item-icon.special {
    i {
      margin-top: 8px;
      margin-left: 1px;
    }
  }

  .nav-item-icon-square {
    background: #e0e0e0 0% 0% no-repeat padding-box;
    border-radius: 6px;
    opacity: 1;
    top: 16px;
    width: 35px;
    height: 35px;
    text-align: center;
    i {
      vertical-align: middle;
      font-size: 16px;
      color: $color-white;
    }
  }

  .fs-20 {
    font-size: 20px !important;
  }

  .text-middle {
    vertical-align: -webkit-baseline-middle !important;
  }

  .burger-container {
    transform: scale(1);
    transition: 0.15s all ease;
  }
}

//.collapsed {
//  margin-left: 6rem !important;
//}
</style>
