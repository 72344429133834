<template>
  <button
    class="c-filledButton"
    :disabled="disabled"
    :style="cssVar"
    :ref="buttonRef"
    @click="handleClick"
  >
    <i v-if="iconClass.length > 0" :class="`icon ${iconClass}`" />
    <span
      v-if="text"
      :style="iconClass.length > 0 ? 'margin: 0 0 0 5px;' : ''"
      >{{ text }}</span
    >
  </button>
</template>

<script>
export default {
  name: 'FilledButton',
  props: {
    text: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    onClick: {
      type: Function,
      default: () => {},
    },
    bgColor: {
      type: String,
      default: '#FF8105',
    },
    borderColor: {
      type: String,
      default: 'none',
    },
    textColor: {
      type: String,
      default: '#FFFFFF',
    },
    iconClass: {
      type: String,
      default: '',
    },
  },
  computed: {
    buttonRef() {
      return `filled-button-${this._uid}`;
    },
    cssVar() {
      return {
        '--bg-color': this.bgColor,
        '--text-color': this.textColor,
        '--border-color': this.borderColor,
        '--darker-bg-color': this.darkerBgColor,
        '--active-bg-color': this.activeBgColor,
        '--disabled-bg-color': this.disabledBgColor,
      };
    },
    darkerBgColor() {
      const amountToDark = -20;
      return (
        '#' +
        this.bgColor
          .replace(/^#/, '')
          .replace(/../g, (color) =>
            (
              '0' +
              Math.min(
                255,
                Math.max(0, parseInt(color, 16) + amountToDark),
              ).toString(16)
            ).substr(-2),
          )
      );
    },
    activeBgColor() {
      const amountToDark = -50;
      return (
        '#' +
        this.bgColor
          .replace(/^#/, '')
          .replace(/../g, (color) =>
            (
              '0' +
              Math.min(
                255,
                Math.max(0, parseInt(color, 16) + amountToDark),
              ).toString(16)
            ).substr(-2),
          )
      );
    },
    disabledBgColor() {
      const amountToDark = -80;
      return (
        '#' +
        this.bgColor
          .replace(/^#/, '')
          .replace(/../g, (color) =>
            (
              '0' +
              Math.min(
                255,
                Math.max(0, parseInt(color, 16) + amountToDark),
              ).toString(16)
            ).substr(-2),
          )
      );
    },
  },
  methods: {
    handleClick() {
      this.onClick();
      this.$refs[this.buttonRef].blur();
    },
  },
};
</script>

<style scoped lang="scss">
.c-filledButton {
  font-family: 'lato_medium', sans-serif;
  font-size: 14px;
  color: var(--text-color);
  transition: background-color 0.3s;
  background-color: var(--bg-color);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 15px 20px;
  min-width: 100px;
  margin: 0 10px;
  height: 38px;
  border-radius: 5px;
  border: 2px solid var(--border-color);
  appearance: none;
  &:hover {
    background-color: var(--darker-bg-color);
  }
  &:active {
    background-color: var(--active-bg-color);
  }
  &:focus {
    outline: none;
  }
  &:disabled {
    background-color: var(--disabled-bg-color);
  }
  .icon {
    fill: $color-white;
    background-color: none;
    margin: 0 5px 0 0;
    font-size: 18px;
  }
}
</style>
