<template>
  <b-form-group :label="label + ':'">
    <b-input-group>
      <b-form-input
        :value="value"
        @input="$emit('input', $event)"
      />

      <template #append>
        <i
          v-if="clearable && value"
          class="fas fa-times fa-lg pointer ml-n3"
          @click="$emit('input', null)"
        />
        <b-form-input
          style="width: 40px"
          type="color"
          :value="value"
          @input="$emit('input', $event)"
        />
      </template>
    </b-input-group>
    <error
      v-if="error"
      class="mt-n1"
      :message="errorMsg"
      variant="no-border no-background"
    />
  </b-form-group>
</template>

<script>
import Error from '@/components/basics/error/CustomError.vue';

export default {
  name: 'FilterColor',
  components: {
    Error
  },
  props: {
    label: {
      type: String,
      default: 'notavailable', // Taken from the locale dictionary
      required: true,
      meta: {
        description: 'Label text',
        example: 'Office'
      }
    },
    placeholder: {
      type: String,
      default: 'select', // Taken from the locale dictionary
      meta: {
        description: 'Texto de referencia para el CustomDropDown',
        example: 'Select'
      }
    },
    value: {
      type: String,
      default: () => ''
    },
    error: {
      type: String,
      default: '',
      meta: {
        description: 'Error state'
      }
    },
    errorMsg: {
      type: String,
      default: () => '',
      meta: {
        description: "Error Message",
        example: "This field is required",
      }
    },
    clearable: {
      type: Boolean,
      default: () => true,
      meta: {
        description: 'Makes the clear button visible',
        example: 'true'
      }
    }
  },
};
</script>
<style lang="scss" scoped>
::v-deep .colorpick-eyedropper-input-trigger {
  display: none;
}
::v-deep .form-control:disabled, ::v-deep .form-control[readonly] {
  background-color: $color-white;
}
::v-deep .input-group-append{
  align-items: center;
}
i {
  color: $color-font-secondary;
  position: absolute;
}
</style>
