<template>
  <portal to="portalModal">
    <div
      class="c-modal"
      :class="{isVisible: addSizeClassToModal}"
    >
      <transition
        name="backdrop"
        @after-leave="addSizeClassToModal = false"
        @before-enter="addSizeClassToModal = true"
      >
        <div
          v-if="isVisible"
          class="c-backdrop"
          @click="onClose"
        />
      </transition>
      <transition name="modal">
        <div
          v-if="isVisible"
          class="c-s-modal"
        >
          <i
            class="fas fa-times-circle c-closeBtn"
            @click="onClose"
          />
          <slot :onClose="onClose">
            Your content here as slot
          </slot>
        </div>
      </transition>
    </div>
  </portal>
</template>
<script>
export default {
  name: 'BaseModal',
  data () {
    return {
      addSizeClassToModal: false
    };
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onClose () {
      this.$emit('close');
    }
  }
};
</script>

<style scoped lang="scss">

  .c-modal{
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top:0;
    left:0;
    z-index: 12;
    &.isVisible{
      width: 100vw;
      height: 100vh;
    }
    .c-backdrop{
      background-color: transparentize(black, 0.5);
      top:0;
      left:0;
      width: 100vw;
      height: 100vh;
      position: fixed;
    }
    .c-s-modal{
      max-width: 500px;
      min-height: 200px;
      background-color: $color-white;
      z-index: 13;
      border-radius: 5px;
      padding: 30px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: relative;

      i.c-closeBtn{

        font-size: 30px;
        color:#e26d00;
        position: absolute;
        top:5px;
        right:5px;
        cursor: pointer;

      }
    }
  }
  .backdrop-enter, .backdrop-leave-to{
    opacity: 0;
  }
  .backdrop-enter-active, .backdrop-leave-active{
    transition: all 0.4s;
  }
  .backdrop-enter-to, .backdrop-leave{
    opacity: 1;
  }
  .modal-enter, .modal-leave-to{
    opacity: 0;
    transform: translateY(-50px);
  }
  .modal-enter-active,  .modal-leave-active{
    transition: all 0.3s;
  }
  .modal-enter-to, .modal-leave{
    opacity: 1;
    transform: translateY(0);
  }
</style>

<style>

</style>
