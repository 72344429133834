<template>
  <base-modal
    :is-visible="isVisible"
    @close="onClose"
  >
    <h4>{{ title }}</h4>
    <p v-if="message">
      {{ message }}
    </p>
    <div class="c-buttons">
      <base-fill-button
        :text="acceptButtonText"
        :on-click="toDoOnAccept"
      />
      <base-fill-button
        bg-color="#6c757d"
        :text="cancelButtonText"
        :on-click="onClose"
      />
    </div>
  </base-modal>
</template>
<script>
import BaseFillButton from '@/components/basics/buttons/BaseFilledButton';
import BaseModal from '@/components/basics/alerts/BaseModal';
export default {
  name: 'BaseConfirmModal',
  components: {
    BaseModal,
    BaseFillButton,
  },
  data() {
    return {
      addSizeClassToModal: false,
    };
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      default: () => '',
    },
    acceptButtonText: {
      type: String,
      default: 'Aceptar',
    },
    toDoOnAccept: {
      type: Function,
      default: () => void 0,
    },
    cancelButtonText: {
      type: String,
      default: 'Cancelar',
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped lang="scss">
h4 {
  font-family: 'lato-bold', sans-serif;
  font-size: 18px;
  text-align: center;
}
p {
  font-family: 'lato', sans-serif;
  font-size: 15px;
  line-height: 22px;
  text-align: center;
  margin-top: 10px;
}

.c-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;

  ::v-deep .c-filledButton {
    margin: 0 20px;
  }
}
</style>

<style>
</style>
