<template>
  <div id="app">
    <side-navbar
      v-if="showSideBar"
      v-b-hover="(isHovered) => (showSideMenuOnMobile = isHovered)"
      :menu-data="userMenu"
      :menu-item-id="this.menuItemId"
      :show-side-menu-on-mobile="showSideMenuOnMobile"
      @toggledSidebar="showSideMenuOnMobile = !showSideMenuOnMobile"
    />
    <div class="c-view">
      <top-navbar
        @toggledSidebar="showSideMenuOnMobile = !showSideMenuOnMobile"
      />
      <AlertMessage
        v-if="!isOfficeSelected"
        :message="$t('selectOfficeForSystem')"
        style="margin-top: 80px"
      />
      <main-menu v-if="selectedMenuItem && isOfficeSelected" />

      <router-view
        v-show="
          !selectedMenuItem && isOfficeSelected && !showBreakNotificationModal
        "
      />
    </div>
    <portal-target multiple name="portalModal" />

    <custom-modal
      id="mdSessionExpiring"
      :no-close-on-back-drop="true"
      size="sm"
      :value="sessionExpiring"
    >
      <template v-slot:header>
        <b-col cols="12">
          <div class="caption mt-2 mb-2 text-center main-content-title">
            {{ $t('sessionAboutToExpireTitle') }}
          </div>
        </b-col>
      </template>
      <b-container class="text-center" fluid>
        <b-row>
          <b-col cols="12">
            <label
              class="color-black modal-confirmation-label"
              v-html="$t('sessionAboutToExpireContent')"
            />
            <timer-countdown-text
              v-if="sessionExpiring"
              id="pnlSessionCountdown"
              :is-countdown="true"
              :on-countdown-finish="handleSessionExpiration"
              :start-time-seconds="sessionCountdownTime"
              :title="$t('sessionWillExpireIn')"
            />
          </b-col>
        </b-row>
      </b-container>
      <template v-slot:footer>
        <b-col cols="12">
          <div
            class="buttons-container d-flex justify-content-center text-center"
          >
            <button
              class="btn btn-primary pl-5 pr-5"
              type="button"
              @click="handleSessionExpirationContinue()"
            >
              <slot>{{ $t('continue') }}</slot>
            </button>
          </div>
        </b-col>
      </template>
    </custom-modal>

    <custom-modal
      id="activeBreakNotificationModal"
      :no-close-on-back-drop="true"
      size="md"
      :value="showBreakNotificationModal"
    >
      <div class="row">
        <div class="col-sm-12 text-center">
          <label> {{ $t('breakExistMessage') }}</label>
          <h4 class="red">
            {{ activeBreakStartTime }}
          </h4>
          <label> {{ $t('timeInBreak') }}</label>
          <h4 class="red">
            {{ activeBreakTimeTotal }}
          </h4>
        </div>
      </div>
      <template v-slot:header>
        <h5 id="activeBreakNotificationModal-title" class="modal-title">
          {{ $t('notification') }}
        </h5>
      </template>
      <template v-slot:footer>
        <div class="col-md-12">
          <base-filled-button
            bg-color="#707070"
            class="float-left"
            icon-class="fal fa-computer-classic"
            :on-click="onContinueWorking"
            :text="$t('continueWorking')"
          />
          <base-filled-button
            class="float-right"
            icon-class="fal fa-sign-out-alt"
            :on-click="onLogout"
            :text="$t('logout')"
          />
        </div>
      </template>
    </custom-modal>

    <AlertMessageToast
      ref="userActionRequest"
      :title="this.$t('takeControl')"
      :content="this.$t('takeControlWaitingRoom')"
      :timer="10000"
      @accepted="handleUserActionAccepted"
      @rejected="handleUserActionRejected"
    />
  </div>
</template>

<script>
import TopNavbar from '@/components/navbar/TopNavbar.vue';
import SideNavbar from '@/components/navbar/SideNavbar.vue';
import MainMenu from '@/components/menu/MainMenu.vue';
import AlertMessage from '@/components/basics/alerts/AlertMessage';
import CustomModal from '@/components/basics/modal/CustomModal.vue';
import TimerCountdownText from '@/components/TimerCountdownText';
import moment from 'moment';
import { clearApiCache } from '@/modules/user/api';
import {
  finish as _finish,
  logout as _logoutBreak,
  login as _loginBreak,
} from './services/BreakManagerService';
import { mapState, mapActions } from 'vuex';
import { logoutWithRedirect } from '@/core/auth';
import { AUTH } from '@/config';
import { modulesName } from '@/store';
import IdentityPermission from '@/constants/IdentityPermission';

import loading from '@/mixins/vue-loading';
import { roomEntryRequestMixin } from '@/mixins/waitingRoom/waitingRoomEntryRequestMixin';
import AlertMessageToast from '@/components/basics/alerts/AlertMessageToast.vue';
import UserActionRequestType from '@/constants/UserActionRequestType';
import ParticipantType from '@/constants/ParticipantType';
import RocketChatService from '@/services/RocketChatService';

export default {
  components: {
    SideNavbar,
    TopNavbar,
    MainMenu,
    AlertMessage,
    CustomModal,
    TimerCountdownText,
    AlertMessageToast,
  },
  mixins: [loading, roomEntryRequestMixin],
  data() {
    return {
      showSideMenuOnMobile: false,
      showBreakNotificationModal: false,
      userActivityThrottlerTimeout: null,
      userOnlineActivityInterval: null,
      sessionExpiring: false,
      requestEnterWaitingRoom: false,
      rocketChatService: RocketChatService,
      activeBreakPendingTimeSeconds: null,
      activeBreakTimeCounterInterval: null,
    };
  },
  computed: {
    ...mapState(modulesName.userModuleName, [
      'userMenu',
      'menuItemId',
      'user',
      'currentActiveLocation',
      'activeBreakAndTurn',
      'profile',
    ]),
    ...mapState(modulesName.appModuleName, [
      'loading',
      'selectedMenuItem',
      'hubLoaded',
    ]),
    sessionCountdownTime() {
      return AUTH.SESSION_TIMEOUT_WARNING;
    },
    showSideBar() {
      return (
        !this.userHasPermissions(IdentityPermission.sidebar.none) &&
        this.isOfficeSelected
      );
    },
    isOfficeSelected() {
      if (
        this.userHasPermissions(IdentityPermission.multi.office) &&
        !this.currentActiveLocation
      )
        return false;
      return true;
    },
    activeBreakStartTime() {
      return this.activeBreakAndTurn.activeBreak
        ? this.$moment(this.activeBreakAndTurn.activeBreak.breakStart).format(
            'MM/DD/yyyy hh:mm A',
          )
        : '';
    },
    activeBreakTimeTotal() {
      return this.secondsToTime(this.activeBreakPendingTimeSeconds);
    },
  },
  watch: {
    loading: {
      immediate: true,
      handler(val) {
        val === true ? this.ShowWait() : this.HideWait();
      },
    },
    'activeBreakAndTurn.activeBreak': {
      deep: true,
      handler: function (newValue, oldValue) {
        const newValueDifferent = newValue !== oldValue;
        const noActiveTurnOrFinalized =
          !this.activeBreakAndTurn.activeTurn ||
          (this.activeBreakAndTurn.activeTurn &&
            this.activeBreakAndTurn.activeTurn.finalized);
        const noAuthorizationPending =
          newValue && !newValue.hasAuthorizationPending;
        const noPendingBreakToStart =
          newValue && !newValue.userHasPendingBreakToStart;
        const noActiveWaitingRoom = !this.activeBreakAndTurn.activeWaitingRoom;
        const breakStarted = newValue && newValue.breakStart !== null;

        if (
          newValueDifferent &&
          noAuthorizationPending &&
          noPendingBreakToStart &&
          ((noActiveTurnOrFinalized && noActiveWaitingRoom) || breakStarted)
        ) {
          clearInterval(this.activeBreakTimeCounterInterval);

          this.activeBreakPendingTimeSeconds = newValue.timePastInSeconds || 0;

          this.activeBreakTimeCounterInterval = setInterval(() => {
            this.activeBreakPendingTimeSeconds += 1;
          }, 1000);

          this.showBreakNotificationModal = true;
        }
      },
    },
    hubLoaded: {
      handler(value) {
        this.subscribeToEvent(value);
      },
    },
    $route() {
      this.subscribeToEvent(this.hubLoaded);
    },
  },
  async mounted() {
    document.addEventListener('visibilitychange', this.updateAccessTime);
    this.activateActivityTracker();
  },
  beforeDestroy() {
    window.removeEventListener('mousemove', this.userActivityThrottler);
    window.removeEventListener('scroll', this.userActivityThrottler);
    window.removeEventListener('keydown', this.userActivityThrottler);
    window.removeEventListener('resize', this.userActivityThrottler);

    clearTimeout(this.userActivityThrottlerTimeout);
    clearTimeout(this.userOnlineActivityInterval);
    clearInterval(this.activeBreakTimeCounterInterval);
  },
  methods: {
    ...mapActions(modulesName.userModuleName, ['setActiveBreak']),
    ...mapActions(modulesName.turnManagerModuleName, ['callNextTurn']),
    activateActivityTracker() {
      window.addEventListener('mousemove', this.userActivityThrottler);
      window.addEventListener('scroll', this.userActivityThrottler);
      window.addEventListener('keydown', this.userActivityThrottler);
      window.addEventListener('resize', this.userActivityThrottler);
      window.addEventListener('beforeunload', this.deactivateActivityTracker);
    },
    deActivateActivityTracker() {
      window.removeEventListener('mousemove', this.userActivityThrottler);
      window.removeEventListener('scroll', this.userActivityThrottler);
      window.removeEventListener('keydown', this.userActivityThrottler);
      window.removeEventListener('resize', this.userActivityThrottler);
      window.removeEventListener(
        'beforeunload',
        this.deactivateActivityTracker,
      );
    },
    updateAccessTime() {
      if (document.hidden) {
        sessionStorage.setItem('hidden-date', JSON.stringify(new Date()));
      } else {
        let hiddenDate = JSON.parse(sessionStorage.getItem('hidden-date'));
        let minutes = moment().diff(moment(hiddenDate), 'minutes', true);
        sessionStorage.removeItem('hidden-date');
        if (minutes > 57) this.onLogout();
      }
    },
    userActivityThrottler() {
      clearTimeout(this.userActivityThrottlerTimeout);

      this.userActivityThrottlerTimeout = setTimeout(() => {
        const activeBreak =
          this.activeBreakAndTurn && this.activeBreakAndTurn.activeBreak;
        const activeTurn =
          this.activeBreakAndTurn &&
          this.activeBreakAndTurn.activeTurn &&
          !this.activeBreakAndTurn.activeTurn.finalized;
        const activeWaitingRoom =
          this.activeBreakAndTurn && this.activeBreakAndTurn.activeWaitingRoom;

        if (activeBreak || activeTurn || activeWaitingRoom) {
          this.userActivityThrottler(); // Restart
          return;
        }

        this.sessionExpiring = true;
        clearTimeout(this.userActivityThrottlerTimeout);
      }, (AUTH.SESSION_TIMEOUT - AUTH.SESSION_TIMEOUT_WARNING) * 1000);
    },
    async handleSessionExpiration() {
      await clearApiCache().finally(async () => {
        if (
          (!this.activeBreakAndTurn || !this.activeBreakAndTurn.activeBreak) &&
          this.currentActiveLocation &&
          this.currentActiveLocation.locationConfigurationId
        ) {
          await _logoutBreak(
            this.currentActiveLocation.locationConfigurationId,
          );
        }
        logoutWithRedirect();
      });
    },
    async handleSessionExpirationContinue() {
      this.sessionExpiring = false;
    },
    secondsToTime(secs) {
      secs = Math.round(secs);
      var hours = Math.floor(secs / (60 * 60))
        .toString()
        .padStart(2, '0');

      var divisor_for_minutes = secs % (60 * 60);
      var minutes = Math.floor(divisor_for_minutes / 60)
        .toString()
        .padStart(2, '0');

      var divisor_for_seconds = divisor_for_minutes % 60;
      var seconds = Math.ceil(divisor_for_seconds).toString().padStart(2, '0');

      var obj = {
        h: hours,
        m: minutes,
        s: seconds,
      };
      return `${obj.h}:${obj.m}:${obj.s}`;
    },
    async onContinueWorking() {
      if (this.activeBreakAndTurn.activeBreak) {
        await _finish(this.activeBreakAndTurn.activeBreak.actualLogId)
          .then(async () => {
            if (this.currentActiveLocation) {
              await _loginBreak(
                this.currentActiveLocation.locationConfigurationId,
                true,
              );
            }

            this.setActiveBreak(null);
            this.showBreakNotificationModal = false;
            this.$store.dispatch(
              `${modulesName.turnManagerModuleName}/refreshTurnManager`,
              null,
              { root: true },
            );
          })
          .catch((error) => this.ShowErrorToast(error.response.data.message));
      }
    },
    async onLogout() {
      await clearApiCache();
      logoutWithRedirect();
    },
    handleUserActionAccepted(waitingRoomId, userId) {
      this.$turnHub.$emit(
        'userActionAccepted',
        waitingRoomId,
        userId,
        UserActionRequestType.GrantControl,
      );
    },
    handleUserActionRejected(waitingRoomId, userId) {
      this.$turnHub.$emit(
        'userActionRejected',
        waitingRoomId,
        userId,
        UserActionRequestType.GrantControl,
      );
    },
    subscribeToEvent(connected) {
      if (!connected) {
        if (this.$turnHub) {
          this.$turnHub.$off('selectedSupervisorUser');
          this.$turnHub.$off('selectedSpecialistUser');
          this.$turnHub.$off('onUserActionRequest');
          this.$turnHub.$off('selectedUser');
          this.$turnHub.$off('userTyping');
        }
        return;
      }

      this.$turnHub.$off('selectedSupervisorUser');
      this.$turnHub.$on(
        'selectedSupervisorUser',
        async (waitingRoomId, participantType) => {
          this.showRoomEntryRequest(
            waitingRoomId,
            this.profile?.userId,
            participantType,
          );
        },
      );

      this.$turnHub.$off('selectedSpecialistUser');
      this.$turnHub.$on('selectedSpecialistUser', async (waitingRoomId) => {
        this.showSpecialistRoomEntryRequest(
          waitingRoomId,
          this.profile?.userId,
        );
      });

      this.$turnHub.$off('onUserActionRequest');
      this.$turnHub.$on(
        'onUserActionRequest',
        async (waitingRoomId, userId, userActionRequestType) => {
          if (userActionRequestType == UserActionRequestType.GrantControl) {
            this.$refs.userActionRequest.showAlert(waitingRoomId, userId);
          } else if (
            userActionRequestType == UserActionRequestType.CallNextTurn
          ) {
            this.callNextTurn();
          }
        },
      );

      this.$turnHub.$off('selectedUser');
      this.$turnHub.$on(
        'selectedUser',
        async (waitingRoomId, participantType) => {
          if (participantType == ParticipantType.Passive) {
            this.showRoomEntryRequest(
              waitingRoomId,
              this.profile?.userId,
              participantType,
            );
          }
        },
      );

      this.$turnHub.$off('userTyping');
      this.$turnHub.$on('userTyping', (chatId, username) => {
        const chatRoom = this.rocketChatService.rooms.find(
          (x) => x.id == chatId,
        );
        if (chatRoom) {
          const member = chatRoom.members.find(
            (x) => x.username === username && x.userId !== this.profile.id,
          );
          if (member) {
            member.typing = true;
            clearTimeout(member.typingTimeout);
            member.typingTimeout = setTimeout(() => {
              member.typing = false;
            }, 2500);
          }
        }
      });
    },
  },
};
</script>

<style lang="scss">
.b-sidebar {
  &.main-chat-sidebar {
    box-shadow: 4px 6px 10px #908d8d !important;
    .b-sidebar-body {
      overflow-y: hidden !important;
    }
  }
}

#app {
  display: flex;

  .c-view {
    width: 100%;
    background-color: $color-app-background;
  }
  .cursor-pointer {
    cursor: pointer;
  }

  .card.search-result {
    border-radius: 12px;
    border: 1px solid #d0cccc;
    .cell {
      font-weight: bold;
      clear: left;
      label {
        color: $color-primary;
        float: left;
        font-size: 16px;
      }
      span {
        color: #1c1d21;
        margin-left: 5px;
        font-size: 16px;
      }
    }
  }
}

.custom-control-input:checked ~ .custom-control-label::before,
.custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #ff8105 !important;
  background-color: #ff8105 !important;
}

.danger-checkbox
  > .custom-control-input:checked
  ~ .custom-control-label::before,
.danger-checkbox
  > .custom-control-input:indeterminate
  ~ .custom-control-label::before {
  border-color: red !important;
  background-color: red !important;
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
</style>

<style lang="scss">
/*GENERAL STYLES*/
@font-face {
  font-family: 'Lato';
  src: local('Lato'), url(./fonts/Lato/Lato-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'lato-medium';
  src: local('Lato'), url(./fonts/Lato/Lato-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'lato-bold';
  src: local('Lato'), url(./fonts/Lato/Lato-Bold.ttf) format('truetype');
}

a:link,
a:visited,
a:hover,
a:active {
  text-decoration: none !important;
}

.modal-backdrop {
  background: gray !important;
  backdrop-filter: blur(2px) !important;
  opacity: 0.7 !important;
}

.modal .main-content-title {
  text-align: left;
  font: normal normal bold 16px/19px Lato;
  letter-spacing: 0px;
  color: $color-primary;
  opacity: 1;
}

textarea {
  resize: none !important;
}
</style>
