import { apiTurnos } from '@/core/api/';
import { serialize } from 'object-to-formdata';

const qs = require('querystring');

export const getLocationsForSpaceCreation = (companyId) => {
  return apiTurnos.get(`/api/location/createspacelist?companyId=${companyId}`);
};

export const getLocations = (companyIds, serviceTypeId) => {
  const qParams = {
    params: {
      companyIds,
      serviceTypeId,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
  };

  return apiTurnos.get('api/location/getall', qParams);
};

export const getLocationsByConfigurationId = (locationConfigurationId) => {
  return apiTurnos.get(
    `api/location/locationconfiguration/${locationConfigurationId}`,
  );
};

export const getLocationsWithServiceInfo = (companyIds) => {
  const qParams = {
    params: {
      companyIds,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
  };

  return apiTurnos.get('api/location/list/services', qParams);
};

export const getLocationList = (companyId = null) => {
  if (companyId != null) {
    return apiTurnos.get(`/api/location/list?companyIds=${companyId}`);
  }
  return apiTurnos.get(`/api/location/list`);
};

export const getLocationListForKiosk = (companyId) => {
  return apiTurnos.get(`/api/location/kiosk/list?companyId=${companyId}`);
};

export const getLocationListForPWA = (companyId, isWithoutPwa, pwaClientId) => {
  companyId;
  isWithoutPwa;
  pwaClientId;

  let querystring = '';
  let searchParams = new URLSearchParams(querystring);
  searchParams.set('companyId', companyId);
  isWithoutPwa != undefined
    ? searchParams.set('isWithoutPwa', isWithoutPwa)
    : '';
  pwaClientId != undefined ? searchParams.set('pwaClientId', pwaClientId) : '';

  return apiTurnos.get(`/api/location/pwa/list?${searchParams.toString()}`);
};

export const getSpecialFieldEnabled = (locationConfigurationId) => {
  return apiTurnos.get(
    `/api/location/${locationConfigurationId}/specialfields`,
  );
};

export const getMiscData = (locationConfigurationId) => {
  return apiTurnos.get(`/api/location/${locationConfigurationId}/miscData`);
};

export const getServiceQueues = (locationConfigurationId) =>
  apiTurnos.get(`api/location/${locationConfigurationId}/services`);

export const getMiscDataForLocations = (locationConfigurationIds) => {
  const qParams = {
    params: {
      locationConfigurationIds,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
  };

  return apiTurnos.get(`/api/location/miscData`, qParams);
};

export const getWaitingTime = (locationConfigurationId) =>
  apiTurnos.get(`/api/location/${locationConfigurationId}/waitingTime`);

export const getCanAskForStation = (locationConfigurationId) =>
  apiTurnos.get(`/api/location/${locationConfigurationId}/station/canask`);

export const getLocationsWithAppointmentsFreeSpaces = (payload) =>
  apiTurnos.get(`/api/location/appointment/freespace/list`, {
    params: { ...payload },
  });

export const getLocationsWithCoordinatedAppointments = (payload) =>
  apiTurnos.get(`/api/location/appointment/coordinated/list`, {
    params: { ...payload },
  });

export const searchLocations = (payload) =>
  apiTurnos.get(`/api/location/search`, { params: { ...payload } });

export const deleteLocation = (locationId) =>
  apiTurnos.delete(`/api/location/${locationId}`);

export const lockUnlockLocation = (locationId) =>
  apiTurnos.put(`/api/location/office/${locationId}/lock`);

export const createUpdateOffice = (model) => {
  const payload = serialize(model, {
    indices: true,
    nullsAsUndefineds: true,
  });

  return apiTurnos.post('/api/location/office', payload, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const getOfficeCategories = () =>
  apiTurnos.get('/api/location/office/categories');

export const getOfficeTypes = () => apiTurnos.get('/api/location/office/types');

export const getOfficeInformation = (locationConfigurationId) =>
  apiTurnos.get(`/api/location/office/${locationConfigurationId}`);

export const getOfficeParameters = (locationConfigurationId) =>
  apiTurnos.get(`/api/location/office/${locationConfigurationId}/parameters`);

export const updateOfficeParameters = (payload) =>
  apiTurnos.put('/api/location/office/parameters', payload);

export const getOfficeTurnhandler = (locationConfigurationId) =>
  apiTurnos.get(`/api/location/office/${locationConfigurationId}/turnhandler`);

export const updateOfficeTurnhandler = (payload) =>
  apiTurnos.put('/api/location/office/turnhandler', payload);

export const getOfficePrinter = (locationConfigurationId) =>
  apiTurnos.get(`/api/location/office/${locationConfigurationId}/printer`);

export const updateOfficePrinter = (payload) =>
  apiTurnos.put('/api/location/office/printer', payload);

export const getOfficeKiosk = (locationConfigurationId) =>
  apiTurnos.get(`/api/location/office/${locationConfigurationId}/kiosk`);

export const updateOfficeKiosk = (payload) =>
  apiTurnos.put('/api/location/office/kiosk', payload);

export const getOfficeDisplay = (locationConfigurationId) =>
  apiTurnos.get(`/api/location/office/${locationConfigurationId}/display`);

export const updateOfficeDisplay = (payload) =>
  apiTurnos.put('/api/location/office/display', payload);

export const getOfficeSurvey = (locationConfigurationId) =>
  apiTurnos.get(`/api/location/office/${locationConfigurationId}/survey`);

export const updateOfficeSurvey = (payload) =>
  apiTurnos.put('/api/location/office/survey', payload);

export const getOfficeAppointment = (locationConfigurationId) =>
  apiTurnos.get(`/api/location/office/${locationConfigurationId}/appointment`);

export const updateOfficeAppointment = (payload) =>
  apiTurnos.put('/api/location/office/appointment', payload);

export const getOfficeIntegration = (locationConfigurationId) =>
  apiTurnos.get(`/api/location/office/${locationConfigurationId}/integration`);

export const updateOfficeIntegration = (payload) =>
  apiTurnos.put('/api/location/office/integration', payload);

export const getOfficeAddress = (locationConfigurationId) =>
  apiTurnos.get(`/api/location/office/${locationConfigurationId}/address`);

export const updateOfficeAdress = (payload) =>
  apiTurnos.put('/api/location/office/address', payload);

export const getOfficeGeo = (locationConfigurationId) =>
  apiTurnos.get(`/api/location/office/${locationConfigurationId}/geo`);

export const updateOfficeGeo = (payload) =>
  apiTurnos.put('/api/location/office/geo', payload);

export const getOfficeExtrafields = (locationConfigurationId) =>
  apiTurnos.get(`/api/location/office/${locationConfigurationId}/extrafields`);

export const updateOfficeExtrafields = (payload) =>
  apiTurnos.put('/api/location/office/extrafields', payload);

export const getOfficeMiscellaneous = (locationConfigurationId) =>
  apiTurnos.get(
    `/api/location/office/${locationConfigurationId}/miscellaneous`,
  );

export const updateOfficeMiscellaneous = (payload) =>
  apiTurnos.put(
    `/api/location/office/${payload.locationConfigurationId}/miscellaneous`,
    payload.miscellaneous,
  );

export const getOfficeAlerts = () =>
  apiTurnos.get('/api/location/office/alerts');

export const getOfficeAlertConfigurations = (locationConfigurationId) =>
  apiTurnos.get(`/api/location/office/${locationConfigurationId}/alerts`);

export const updateOfficeAlerts = (payload) =>
  apiTurnos.put(
    `/api/location/office/${payload.locationConfigurationId}/alerts`,
    payload.configurations,
  );

export const deleteOfficeAlertConfiguration = (id) =>
  apiTurnos.delete(`/api/location/office/alerts/${id}`);

export const getOfficeNotifications = (locationConfigurationId) =>
  apiTurnos.get(
    `/api/location/office/${locationConfigurationId}/notifications`,
  );

export const copyOffice = (payload) =>
  apiTurnos.post(
    `/api/location/office/${payload.locationConfigurationId}/clone`,
    payload,
  );

export const getSurveyLocationsList = (locationConfigurationIds) => {
  return apiTurnos.get(`api/location/survey/list`, {
    params: {
      locationConfigurationIds,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
  });
};

export const deleteImage = (id) =>
  apiTurnos.delete(`/api/location/${id}/image/delete`);

export const canCoordinateInternalAppointments = (locationConfigurationId) =>
  apiTurnos.get(
    `/api/location/${locationConfigurationId}/coordinateinternalappointments`,
  );

export const canCoordinateInternalAppointmentsToday = (
  locationConfigurationId,
) =>
  apiTurnos.get(
    `/api/location/${locationConfigurationId}/coordinateinternalappointmentsToday`,
  );

export const getCoordinators = (locationConfigurationId) =>
  apiTurnos.get(`/api/location/${locationConfigurationId}/coordinators`);

export const verifyDigitalCommunityFlag = (locationConfigurationId) =>
  apiTurnos.get(`/api/location/verifydigitalcommunityflag`, {
    params: {
      locationConfigurationId,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
  });

export const maximumDaysToPreventAppointments = (locationConfigurationId) =>
  apiTurnos.get(
    `/api/location/${locationConfigurationId}/maximundayspreventappointments`,
  );

export const getUAGMStudentInfo = (locationConfigurationId, studentId) =>
  apiTurnos.get(
    `/api/location/${locationConfigurationId}/uagmstudent/${studentId}`,
  );

export const haveCommunicationModuleEnabled = (locationConfigurationId) =>
  apiTurnos.get(
    `/api/location/${locationConfigurationId}/have/communication/enable`,
  );

export const getExtraField2ByExtraField1 = (extraField1) =>
  apiTurnos.get(`/api/location/extrafield2/${extraField1}`);
