<template>
  <b-form-group class="cus-radiobutton-container">
    <b-form-radio-group
      v-model="selected"
      :name="name"
    >
      <b-form-radio
        v-for="option in options"
        :key="option.value"
        :value="option.value"
      >
        <span>{{ $t(option.text) }}</span>
      </b-form-radio>
    </b-form-radio-group>
  </b-form-group>
</template>
<script>
export default {
  name: 'BaseRadiobuttonGroup',
  props: {
    options: {
      type: Array,
      default () {
        return { text: '', value: '' };
      },
      required: true
    },
    initialSelected: {
      type: Number,
      default: 1,
      required: true
    },
    name: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      selected: this.initialSelected
    };
  },
  watch: {
    selected (val) {
      this.selected = val;
      this.$emit('change', val);
      this.$emit('input', val);
    },
    initialSelected (val) {
      this.selected = val;
    }
  }
};
</script>
<style lang="scss">
.cus-radiobutton-container {
  label {
    position: relative;
  }
  .cus-radiobutton-label {
    top: 5px;
  }

  .custom-radio .custom-control-input:checked ~ .custom-control-label::after {
    content: '';
    opacity: 1;
    border-radius: 50%;
    position: absolute;
    top: 0.55rem;
    left: -0.6rem;
    height: 10px;
    width: 10px;
    background-color: $color-primary;
    // visibility: hidden;
  }

  .custom-control-input:checked ~ .custom-control-label::before,
  .custom-control-label::before {
    color: $color-white;
    border-color: $color-white;
    background-color: $color-white;
    border: 1px solid $color-label-default;
    width: 20px;
    height: 20px;
    border: 1px solid $color-label-default;
    display: inline-block;
    border-radius: 50%;
    transition: all linear 0.3s;
    left: -0.9rem;
  }

  .custom-radio .custom-control-input:checked ~ .custom-control-label::after {
    background-image: none !important;
  }

  .custom-control.custom-control-inline.custom-radio {
    min-height: 31px !important;
  }

  .custom-radio {
    padding-right: 15px;
    padding-left: 15px;
    .custom-control-label {
      span {
        padding-left: 15px;
        top: 5px !important;
        position: relative;
      }
    }
  }

  // span {
  //   width: 20px;
  //   height: 20px;
  //   border: 1px solid $color-label-default;
  //   display: inline-block;
  //   border-radius: 50%;
  //   transition: all linear 0.3s;
  //   &:after {
  //     content: '';
  //     opacity: 1;
  //     border-radius: 50%;
  //     position: absolute;
  //     top: 5px;
  //     left: 5px;
  //     height: 10px;
  //     width: 10px;
  //     background-color: $color-primary;
  //     visibility: hidden;
  //   }
  // }
  // input {
  //   display: none;
  //   &:checked ~ span {
  //     background: $color-white;
  //     &:after {
  //       visibility: visible;
  //     }
  //   }
  // }
}
</style>
