<template>
  <div class="chat-sidebar" :class="{ open: currentRoomId === 0 }" tabindex="0">
    <div
      class="room d-flex"
      :class="{ active: currentRoomId == room.id }"
      :key="i"
      v-for="(room, i) in roomOptions"
      @click="$emit('room-selected', room.id)"
    >
      <div class="room-abbreviation">
        <div class="abbreviation">
          {{ room.abbreviation }}
        </div>
        <div
          class="small-noti"
          :class="{ mention: room.mention && !room.loading, unread: room.unread && !room.loading}"
        >
          <loading
            :active="room.loading"
            color="#F37E00"
            :is-full-page="false"
            :width="15"
            :height="15"
          />
        </div>
      </div>
      <div class="room-information mt-2">
        <div class="full-name" :class="{ big: !room.lastMessage.msg }">
          #{{ room.turnNumber }}
        </div>
        <div class="status">
          <span v-if="room.typing"> {{ $t('typing') }}... </span>
          <span v-else>
            {{ getTextMessage(room.lastMessage) }}
          </span>
        </div>
      </div>
      <div class="noti">
        <loading
          :active="room.loading"
          color="#F37E00"
          :is-full-page="false"
          :width="20"
          :height="20"
          :z-index="50"
        />
        <div
          v-if="!room.loading"
          class="state"
          :class="{ mention: room.mention, unread: room.unread }"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ChatSidebar',
  data() {
    return {};
  },
  props: {
    rooms: {
      type: Array,
      default: () => [],
    },
    currentRoomId: {
      type: [String, Number],
      required: true,
    },
  },
  computed: {
    roomOptions() {
      return [...this.rooms].sort((a, b) => {
        const dateMessageA = a.messages[0]?.date;
        const dateMessageB = b.messages[0]?.date;
        return dateMessageB - dateMessageA;
      });
    },
  },
  methods: {
    getTextMessage(message) {
      let msg = message.msg;

      const mentions = message.mentions || [];

      for (const mention of mentions) {
        let label = '@';
        if (mention.username === 'all') {
          label += this.$t('all');
        } else if (mention.username === 'here') {
          label += this.$t('here');
        } else if (mention.name) {
          label += mention.name;
        } else {
          label += mention.username
        }
        msg = msg.replaceAll(`@${mention.username}`, label);
      }

      return msg;
    }
  },
};
</script>

<style scoped lang="scss">
.chat-sidebar {
  width: 86px;
  min-height: calc(100vh - 70px);
  flex-shrink: 0;
  background: #fff;
  box-shadow: 4px 4px 10px #e6e6e6;
  transition: width 0.3s, transform 0.3s;
  overflow: hidden;
  position: absolute;
  z-index: 1;
  .room {
    height: 66px;
    cursor: pointer;
    position: relative;
    // border-bottom: 1px solid #dededf;

    .room-abbreviation {
      width: 86px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .abbreviation {
        border: 2px solid #ff8106;
        border-radius: 50%;
        height: 50px;
        width: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        font-weight: bolder;
        color: #3f5b60;
      }
      .small-noti {
        display: block;
        width: 15px;
        height: 15px;
        position: absolute;
        top: 3px;
        right: 20px;
        border-radius: 50%;
        &.unread {
          background-color: #ff8106;
        }
        &.mention {
          background-color: #fb2a2a;
        }
      }
    }
    .room-information {
      width: 155px;
      display: none;
      .full-name {
        color: #1c1d21;
        font-size: 16px;
        &.big {
          font-size: 18px;
          height: 100%;
          display: flex;
          align-items: center;
        }
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .status {
        color: #707070;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 138px;
      }
    }
    .noti {
      display: none;
    }
    &.active {
      background-color: #fee5a8;
      .abbreviation {
        background-color: #ff8106;
        color: #fff;
      }
    }
  }
  &:hover,
  &.open {
    width: 240px;

    .room-abbreviation {
      .small-noti {
        display: none;
      }
    }

    .room-information {
      display: block;
    }

    .noti {
      display: block;
      position: relative;
      width: 30px;
      .state {
        position: absolute;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        top: 18px;
        &.unread {
          background-color: #ff8106;
        }
        &.mention {
          background-color: #fb2a2a;
        }
      }
    }
  }
}
</style>
