<template>
  <span class="cus-checkbox-container">
    <label class="cus-checkbox-label">
      <input
        :id="id"
        v-model="selectedValue"
        :name="name"
        type="checkbox"
        @change="updateValue"
      />
      <span />
    </label>
    <label
      v-if="label"
      class="ml-2 strong color-black"
    >{{ label }}</label>
  </span>
</template>
<script>
export default {
  name: 'BaseCheckbox',
  props: {
    value: {
      type: Boolean
    },
    id: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false,
      meta: {
        example: "true",
      }
    }
  },
  data () {
    return {
      selectedValue: this.value
    };
  },
  watch: {
    value: function (value) {
      this.selectedValue = value;
    }
  },
  methods: {
    updateValue() {
      if (!this.disabled) {
        this.$emit("change", this.selectedValue);
      }
    }
  }
};
</script>
<style lang="scss">
.cus-checkbox-container {
  label {
    position: relative;
  }
  .cus-checkbox-label {
    top: 5px;
  }
  span {
    width: 20px;
    height: 20px;
    border: 1px solid $color-label-default;
    display: inline-block;
    border-radius: 4px;
    transition: all linear 0.3s;
    &:after {
      content: '';
      opacity: 1;
      border-radius: 1px;
      position: absolute;
      top: 1px;
      left: 6px;
      border-bottom: 0.25em solid $color-white;
      border-right: 0.25em solid $color-white;
      height: 13px;
      width: 8px;
      transform: rotate(45deg);
      visibility: hidden;
    }
  }
  input {
    display: none;
    &:checked ~ span {
      background: $color-primary;
      &:after {
        visibility: visible;
      }
    }
  }
}
</style>
