<template>
  <button
    :id="id"
    :class="`${size} outline-${outline} ${disabled ? 'disabled' : ''}`"
    :disabled="disabled"
    :title="tooltip"
    @click="onClick"
  >
    <div class="content">
      <i :class="`${icon}`" />
    </div>
  </button>
</template>

<script>
export default {
  components: {},
  props: {
    id: {
      type: String,
      default: () => '',
      meta: {
        description: 'Id of the tag',
        example: 'btnCompleteTurn',
      },
    },
    disabled: {
      type: Boolean,
      required: false,
      meta: {
        description: 'Disabled flag',
      },
    },
    icon: {
      type: String,
      default: null,
      meta: {
        description:
          'Go down, to the css section and look for a list of available icons. Use any class identifier found there.',
      },
    },
    size: {
      type: String,
      default: 'medium',
      meta: {
        description: 'Either small or large',
      },
    },
    outline: {
      type: String,
      default: () => 'orange',
    },
    tooltip: {
      type: String,
      default: () => '',
    },
  },
  methods: {
    onClick() {
      this.$emit('on-click');
    },
  },
};
</script>

<style scoped lang="scss">
button {
  border: none;
  border-radius: 50%;
  background-color: #fff2e6;
}

button i {
  font-weight: normal !important;
}

button.small {
  width: 45px;
  height: 45px;
}

button.medium {
  width: 70px;
  height: 70px;
}

button.large {
  width: 155px;
  height: 155px;
}

button:focus {
  border: none;
}

button .content {
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

button.large .content {
  font-size: 74px;
  width: 135px;
  height: 135px;
  padding-top: 8px;
}

button.small .content {
  border-width: 2px !important;
  width: 34px;
  height: 34px;
  i {
    margin-top: 7px;
    font-size: 17px;
  }
}

button.small.outline-orange {
  background-image: radial-gradient(
    circle 38px at 47.3% 50.7%,
    white 15%,
    $color-primary 95%
  );
}

button.small.outline-blue {
  background-image: radial-gradient(
    circle 38px at 47.3% 50.7%,
    white 15%,
    #7da0d2 95%
  );
}

button.small.outline-purple {
  background-image: radial-gradient(
    circle 38px at 47.3% 50.7%,
    white 15%,
    #7B83EB 95%
  );
}

button.small.outline-red {
  background-image: radial-gradient(
    circle 38px at 47.3% 50.7%,
    white 15%,
    red 95%
  );
}

button.medium .content {
  width: 60px;
  height: 60px;
  i {
    margin-top: 12px;
    font-size: 30px;
  }
}

button.medium.outline-orange {
  background-image: radial-gradient(
    circle 90px at 47.3% 50.7%,
    white 15%,
    $color-primary 95%
  );
}

button.medium.outline-blue {
  background-image: radial-gradient(
    circle 90px at 47.3% 50.7%,
    white 15%,
    #7da0d2 95%
  );
}

button.medium.outline-red {
  background-image: radial-gradient(
    circle 90px at 47.3% 50.7%,
    white 15%,
    red 95%
  );
}

button.large.outline-orange {
  background-image: radial-gradient(
    circle 260px at 47.3% 50.7%,
    white 15%,
    $color-primary 95%
  );
}

button.large.outline-blue {
  background-image: radial-gradient(
    circle 260px at 47.3% 50.7%,
    white 15%,
    #7da0d2 95%
  );
}

button.large.outline-red {
  background-image: radial-gradient(
    circle 260px at 47.3% 50.7%,
    white 15%,
    red 95%
  );
}

button.outline-orange .content {
  color: $color-primary;
  border: $color-primary solid 3px;
}

button.outline-blue .content {
  color: #7da0d2;
  border: #7da0d2 solid 3px;
}

button.outline-purple .content {
  color: #464EB8;
  border: #464EB8 solid 3px;
}

button.outline-red .content {
  color: red;
  border: red solid 3px;
}

button.small.disabled {
  background-image: radial-gradient(
    circle 92px at 47.3% 50.7%,
    white 15%,
    #b4b4b4 95%
  ) !important;
}

button.medium.disabled {
  background-image: radial-gradient(
    circle 92px at 47.3% 50.7%,
    white 15%,
    #b4b4b4 95%
  ) !important;
}

button.large.disabled {
  background-image: radial-gradient(
    circle 92px at 47.3% 50.7%,
    white 15%,
    #b4b4b4 95%
  ) !important;
}

button.disabled .content {
  color: #b4b4b4 !important;
  border: #b4b4b4 solid 3px !important;
}
</style>
