<template>
  <div>
    <div class="text-center">
      <div
        id="popover-button-sync"
        v-if="showCallControls"
        class="nav-item-icon chat-icon special mr-2"
        @click="showPopover = !showPopover && !isRejoinActive"
      >
        <i class="fal fa-phone text-middle" />
      </div>
      <b-popover
        target="popover-button-sync"
        v-if="showCallControls"
        placement="bottom"
        :key="dataMutedKey"
        :show.sync="showPopover"
      >
        <template>
          <div v-if="showCallControls" class="row call-controls-row">
            <button @click="makeCall" style="display: none">Make Call</button>
            <div class="col-sm-6 action-button">
              <action-circle-button
                v-if="!canRejoin"
                id="btnDropCall"
                icon="fal fa-phone-slash"
                size="small"
                outline="red"
                :title="$t('finish')"
                @on-click="dropCall"
              />
            </div>
            <div class="col-sm-6 action-button">
              <action-circle-button
                v-if="!canRejoin && canMute"
                id="btnMuteCall"
                :icon="getMutedIcon"
                size="small"
                :title="$t(isMuted ? 'unmute' : 'mute')"
                :disabled="isMuteDisabled"
                @on-click="toggleMute"
              />
            </div>
            <div class="col-sm-12 text-center action-button">
              <action-circle-button
                v-if="canRejoin"
                id="btnReJoinCall"
                icon="fal fa-phone"
                size="small"
                outline="red"
                :title="$t('reJoinCall')"
                @on-click="reJoinCall"
              />
            </div>
          </div>
        </template>
      </b-popover>
    </div>
    <div></div>

    <custom-modal
      :value="showIncomingCall"
      :no-close-on-back-drop="true"
      :no-close-on-esc="true"
      size="sm"
    >
      <div class="row">
        <span class="incoming-text"> {{ $t('incomingCall') }}</span>
      </div>
      <div class="row incoming-buttons-row">
        <div class="incoming-button" :class="{ 'col-6': canDecline, 'col-12': !canDecline }">
          <action-circle-button
            id="btnAnswerCall"
            icon="fal fa-phone"
            size="medium"
            outline="blue"
            :title="$t('answer')"
            @on-click="answerCall"
          />
        </div>
        <div class="col-6 incoming-button">
          <action-circle-button
            v-if="canDecline"
            id="btnDropCall"
            icon="fal fa-phone-slash"
            size="medium"
            outline="red"
            :title="$t('reject')"
            @on-click="rejectCall"
          />
        </div>
      </div>
    </custom-modal>
  </div>
</template>
<script>
import JsSIP from 'jssip';
import AudioRinging from '@/assets/sounds/ringing.mp3';
import ActionCircleButton from '@/components/ActionCircleButton';
import { mapState, mapActions } from 'vuex';
import { modulesName } from '@/store';
import CustomModal from '@/components/basics/modal/CustomModal.vue';
import WaitingRoomStatus from '@/constants/status/WaitingRoomStatus';
import CallStatus from '@/constants/CallStatus';
import ParticipantType from '@/constants/ParticipantType'

import { 
  muteOrUnmuteCall as _muteOrUnmuteCall, 
  checkIfUserHasActiveCall as _checkIfUserHasActiveCall
} from '@/services/CommunicationService';

import { 
  acceptCall as _acceptCall, 
  declineCall as _declineCall 
} from '@/services/WaitingRoomService';

export default {
  name: 'WebPhone',

  data() {
    return {
      ua: null,
      currentSession: null,
      ringingSound: null,
      callTimeout: null,
      isMuted: false,
      status: 'Not Connected',
      showIncomingCall: false,
      incomingCaller: '',
      dataMutedKey: 0,
      showPopover: false,
      isRejoinActive: false,
      canDecline: true
    };
  },
  components: {
    ActionCircleButton,
    CustomModal,
  },
  watch: {
    async virtualPhoneInfo(value) {
      if (
        value &&
        value.communicationServerAddress &&
        value.virtualPhoneExtension &&
        value.virtualPhonePassword
      ) {
        this.initJsSIP();
      }
    },
    hubLoaded: {
      immediate: true,
      handler(value) {
        this.subscribeToEvent(value);
      },
    },
    activeWaitingRoom: {
      immediate: true,
      handler(value, previousValue) {
        if(previousValue && value == null) {
          this.isMuted = false;
          this.showPopover = false;
          this.clearCallTimeout();
          this.setStatus('Call Dropped');
          this.showIncomingCall = false;
          this.currentSession?.terminate();
        }
      },
    },
    watch: {
      'phoneCallChannel.onHold': function(newValue) {
        if(newValue == undefined)
          return;

        this.isMuted = newValue;
      }
    },
  },
  computed: {
    ...mapState(modulesName.userModuleName, ['profile', 'virtualPhoneInfo']),
    ...mapState(modulesName.appModuleName, ['hubLoaded']),
    getIsMuted() {
      return this.isMuted;
    },
    getMutedIcon() {
      return this.isMuted ? 'fal fa-microphone-slash' : 'fal fa-microphone';
    },
    ...mapState(modulesName.turnManagerModuleName, [
      'activeTurn',
      'activeWaitingRoom',
    ]),
    phoneCallChannel() {
      return this.activeWaitingRoom.channels.find(channel => channel.type === 'PhoneCall');
    },
    isMuteDisabled() {
      return this.phoneCallChannel && this.phoneCallChannel.onHold;
    },
    canMute() {
      return this.activeWaitingRoom && 
        this.activeWaitingRoom.participants.some(
          (x) => x.participantType === ParticipantType.Active && 
            x.userId === this.profile.userId);
    },
    canRejoin() {
      if(this.activeWaitingRoom && 
         (this.activeWaitingRoom.waitingRoomStatus == WaitingRoomStatus.WaitingCitizen ||
          this.activeWaitingRoom.waitingRoomStatus == WaitingRoomStatus.SessionInProgress) &&
         this.currentSession == null) {
        return true;
      }

      if(this.ua != null && this.ua.sessions &&
         this.ua.sessions.length && 
         (this.ua.sessions[0].status === CallStatus.STATUS_NULL ||
         this.ua.sessions[0].status === CallStatus.STATUS_CANCELED ||
         this.ua.sessions[0].status === CallStatus.STATUS_TERMINATED)) {
        return true;
      }
      
      return false;
    },
    showCallControls() {
      return this.canRejoin || (this.currentSession && this.currentSession.isEstablished());
    },
  },
  methods: {
    ...mapActions(modulesName.turnManagerModuleName, [
      'onWaitingRoomCallDeclined',
      'onWaitingRoomCallAccepted'
    ]),
    initJsSIP() {
      const socket = new JsSIP.WebSocketInterface(
        `wss://${this.virtualPhoneInfo.communicationServerAddress}:8089/ws`,
      );
      const configuration = {
        uri: `sip:${this.virtualPhoneInfo.virtualPhoneExtension}@${this.virtualPhoneInfo.communicationServerAddress}`,
        password: this.virtualPhoneInfo.virtualPhonePassword,
        authorization_user: this.virtualPhoneInfo.virtualPhoneExtension,
        sockets: [socket],
      };

      this.ua = new JsSIP.UA(configuration);

      var options = {
        all: true,
      };

      this.ua.unregister(options);

      this.setStatus('Connected');

      this.ua.on('newRTCSession', (e) => {
        const session = e.session;

        if (session.direction === 'incoming') {
          // If the user has an active waiting room, the decline button
          // should only be visible for the active one and only at the FIRST CALL
          if (this.activeWaitingRoom) {
            this.canDecline = this.activeWaitingRoom.waitingRoomStatus < WaitingRoomStatus.SessionInProgress && 
                              this.activeWaitingRoom.participants.some(
                              (x) => x.participantType === ParticipantType.Active &&
                                    x.userId === this.profile.userId &&
                                    (x.previousParticipantTypes == null || x.previousParticipantTypes.length == 0)
                              ) && (this.activeTurn == null || this.activeTurn.finalized);
          } else {
            this.canDecline = true;
          }

          this.isMuted = false;
          this.currentSession = session;
          this.incomingCaller =
            session.remote_identity.display_name ||
            session.remote_identity.uri.user;
          this.showIncomingCall = true;
          this.ringingSound.play();

          this.callTimeout = setTimeout(() => {
            this.dropCall();
            this.setStatus('Call Timed Out');
          }, 30000);
        }

        session.on('failed', () => {
          this.isMuted = false;
          this.showPopover = false;
          this.clearCallTimeout();
          this.setStatus('Call Failed');
          this.showIncomingCall = false;
          try {
            this.ringingSound.pause();
          } catch (error) {
            this.ShowErrorToast(this.$t('systemError'));
          }
        });

        session.on('ended', () => {
          this.isMuted = false;
          this.showPopover = false;
          this.clearCallTimeout();
          this.setStatus('Call Ended by the other party');
          this.showIncomingCall = false;
          try {
            this.ringingSound.pause();
          } catch (error) {
            this.ShowErrorToast(this.$t('systemError'));
          }
        });

        session.on('bye', function () {
          this.isMuted = false;
          this.showPopover = false;
          this.clearCallTimeout();
          this.setStatus('Call Ended by the other party2');
          this.showIncomingCall = false;
          try {
            this.ringingSound.pause();
          } catch (error) {
            this.ShowErrorToast(this.$t('systemError'));
          }
        });

        // Listen for the 'peerconnection' event to get the connection object
        session.on('peerconnection', (e) => {
          const pc = e.peerconnection;

          // Add event listener for the 'addstream' event
          pc.onaddstream = (event) => {
            const audioElement = document.createElement('audio');
            audioElement.srcObject = event.stream;
            audioElement.autoplay = true;
            document.body.appendChild(audioElement);
          };
        });
      });

      this.ua.start();
    },
    async reJoinCall() {
      this.showPopover = false;

      await _checkIfUserHasActiveCall(true);
    },
    makeCall() {
      this.clearCallTimeout();
      this.isMuted = false;
      this.showPopover = false;
      const destination = prompt(
        'Enter SIP URI to call:',
        'sip:100@cee-pbx-webrtc.turnospr.com',
      );
      if (destination) {
        const options = {
          eventHandlers: {
            accepted: () => {
              this.setStatus('Call Connected');
              try {
                this.ringingSound.pause();
              } catch (error) {
                this.ShowErrorToast(this.$t('systemError'));
              }
            },
          },
        };

        this.currentSession = this.ua.call(destination, options);
      }
    },
    async answerCall() {
      this.isMuted = false;
      this.clearCallTimeout();
      this.currentSession.answer({
        mediaConstraints: { audio: true, video: false },
      });
      this.setStatus('Call Answered');
      this.showIncomingCall = false;
      try {
        this.ringingSound.pause();
      } catch (error) {
        this.ShowErrorToast(this.$t('systemError'));
      }

      if(this.activeWaitingRoom) {
        await _acceptCall({ waitingRoomId: this.activeWaitingRoom.waitingRoomId }).then(async () => {
          // Call subscribed method
          if(this.onWaitingRoomCallAccepted) {
            await this.onWaitingRoomCallAccepted();
          }
        });
      }
    },
    async rejectCall() {
      this.isMuted = false;
      this.clearCallTimeout();
      try {
        this.ringingSound.pause();
      } catch (error) {
        this.ShowErrorToast(this.$t('systemError'));
      }
      if (this.currentSession) {
        this.currentSession.terminate();
        this.setStatus('Call Rejected');
        this.showIncomingCall = false;
        try {
          this.ringingSound.pause();
        } catch (error) {
          this.ShowErrorToast(this.$t('systemError'));
        }
      }

      if(this.activeWaitingRoom) {
        const isActiveAndFirstCall = this.activeWaitingRoom.participants.some(
          (x) => x.participantType === ParticipantType.Active &&
                  x.userId === this.profile.userId &&
                  (x.previousParticipantTypes == null || x.previousParticipantTypes.length == 0)
        );

        if (isActiveAndFirstCall) {
          await _declineCall({ waitingRoomId: this.activeWaitingRoom.waitingRoomId }).then(async () => {
            // Call subscribed method
            if(this.onWaitingRoomCallDeclined) {
              await this.onWaitingRoomCallDeclined();
            }
          });
        }
      }
    },
    dropCall() {
      this.isMuted = false;
      this.showPopover = false;
      this.clearCallTimeout();
      if (this.currentSession) {
        this.currentSession.terminate();
        this.setStatus('Call Dropped');
        this.showIncomingCall = false;
        try {
          this.ringingSound.pause();
        } catch (error) {
          this.ShowErrorToast(this.$t('systemError'));
        }
      }
    },
    async toggleMute() {
      if (this.currentSession) {
        if (this.isMuted) {
          if(this.activeWaitingRoom)
          {
            await _muteOrUnmuteCall(this.activeWaitingRoom.waitingRoomId, {
              mute: false,
              userId: this.profile.userId
            }).then(() => this.isMuted = false);
          }
          else {
            this.currentSession.unmute({ audio: true });
          }
          
        } else {
          if(this.activeWaitingRoom)
          {
            await _muteOrUnmuteCall(this.activeWaitingRoom.waitingRoomId, {
              mute: true,
              userId: this.profile.userId
            }).then(() => this.isMuted = true);
          }
          else 
          {
            this.currentSession.mute({ audio: true });
          }
        }
        this.setStatus('Call ' + (this.isMuted ? 'Muted' : 'Unmuted'));
        this.dataMutedKey++;
      }
    },
    setStatus(status) {
      this.status = status;
    },
    clearCallTimeout() {
      if (this.callTimeout) {
        clearTimeout(this.callTimeout);
        this.callTimeout = null;
      }
    },
    subscribeToEvent(connected) {
      if (!connected) {
        if(this.$turnHub) {
          this.$turnHub.$off('onWaitingRoomStatusChanged');
          this.$turnHub.$off('waitingRoomFinished');
        }
        return;
      }

      this.$turnHub.$off('onWaitingRoomStatusChanged');
      this.$turnHub.$on('onWaitingRoomStatusChanged', (status) => {
        const validStatus = [
          WaitingRoomStatus.SessionFinished,
          WaitingRoomStatus.SessionCompleted,
          WaitingRoomStatus.SessionCancelled,
        ];

        if (validStatus.includes(status)) {
          this.isMuted = false;
          this.showPopover = false;
          this.clearCallTimeout();
          this.setStatus('Call Dropped');
          this.showIncomingCall = false;
        }
      });

      this.$turnHub.$off('waitingRoomFinished');
      this.$turnHub.$on('waitingRoomFinished', (status) => {
        const validStatus = [
          WaitingRoomStatus.SessionFinished,
          WaitingRoomStatus.SessionCompleted,
          WaitingRoomStatus.SessionCancelled,
        ];

        if (validStatus.includes(status)) {
          this.isMuted = false;
          this.showPopover = false;
          this.clearCallTimeout();
          this.setStatus('Call Dropped');
          this.showIncomingCall = false;
        }
      });
    },
  },
  async mounted() {
    this.ringingSound = new Audio(AudioRinging);
    this.ringingSound.loop = true;
  },
};
</script>

<style>
.popup {
  height: 10rem;
  width: 20rem;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}
.hidden {
  position: absolute;
  left: -9999px;
}
.loading-sm svg {
  scale: 0.5;
}

.incoming-text {
  width: 100%;
  text-align: center;
  font-weight: bold;
  font-size: 20px;
  color: #212529;
  font-family: Lato;
  margin-top: 10px;
}

.incoming-buttons-row {
  margin-top: 30px;
}

.incoming-button {
  text-align: center;
}

.call-controls-row {
  width: 140px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.4s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
