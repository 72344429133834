<template>
  <div class="row p-3">
    <loading :active="loading" color="#F37E00" :is-full-page="false" />
    <div
      v-for="(station, i) in stations"
      :key="i"
      class="col-md-12 station-box px-2 py-3 text-center mb-2"
      @click="onSelect(station)"
    >
      <i class="fal fa-chevron-circle-right float-left"></i> {{ station.name }}
    </div>
  </div>
</template>

<script>
import {
  getPermissionsListById as _getPermissionsListById,
  getStationList as _getStationList,
} from '@/services/StationService';
import { modulesName } from '@/store';
import { mapGetters } from 'vuex';

export default {
  name: 'StationList',
  props: {
    location: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      stations: [],
      loading: false,
    };
  },
  computed: {
    ...mapGetters(modulesName.userModuleName, ['currentActiveLocation']),
  },
  mounted() {
    _getStationList(this.location.locationConfigurationId).then((response) => {
      this.stations = response.data;
    });
  },
  methods: {
    async onSelect(station) {
      this.loading = true;

      await _getPermissionsListById(station.id)
        .then((response) => {
          this.$store.dispatch(
            `${modulesName.userModuleName}/setCurrentActiveStation`,
            {
              id: station.id,
              locationConfigurationId: this.location.locationConfigurationId,
              serviceQueueId: station.serviceQueueId,
              fixedServiceQueue: station.fixedServiceQueue,
              permissions: response.data,
            },
          );
          this.$emit('close');
          if (
            !this.currentActiveLocation ||
            this.location.locationConfigurationId !=
              this.currentActiveLocation.locationConfigurationId
          ) {
            this.$store.dispatch(
              `${modulesName.userModuleName}/setCurrentActiveLocation`,
              this.location,
            );
          }
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message))
        .finally(() => (this.loading = false));
    },
  },
};
</script>
<style lang="scss" scoped>
.row {
  min-height: 300px;
  height: 600px;
  overflow-y: scroll;
  .station-box {
    border: 1px solid #ff8105;
    border-radius: 10px;
    color: #ff8105;
    font-weight: bold;
    cursor: pointer;
    i {
      font-size: 20px;
    }
    &:hover {
      background: #ff8105;
      color: white;
    }
  }
}
</style>
