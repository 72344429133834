<template>
  <div
    class="AlertMessage w-100 h-50 rounded py-4 mb-3 d-flex justify-content-center align-items-center"
    :style="{backgroundColor: bgColor}"
  >
    <i
      v-if="iconClass.length > 0"
      class="h3 mx-3 my-0"
      :class="iconClass"
      :style="{color: bgColor ? darkerBgColor :  '#FF0000'}"
    />
    <p
      class="m-0 font-weight-bold"
      :style="{color: bgColor ? darkerBgColor :  '#FF0000'}"
    >
      {{ message }}
    </p>
  </div>
</template>
<script>
export default {
    name: 'AlertMessage',
    props:{
        iconClass: {
            type: String,
            required: false,
            default: 'fal fa-exclamation-triangle'
        },
        message: {
            type: String,
            required: true,
            default: ''
        },
        bgColor: {
            type: String,
            required: false,
            default: ''
        }
    },
    computed: {
        darkerBgColor () {
          if(this.bgColor){
            const amountToDark = -130;
            return '#' + this.bgColor.replace(/^#/, '').replace(/../g, color => ('0' + Math.min(255, Math.max(0, parseInt(color, 16) + amountToDark)).toString(16)).substr(-2));
          }else{
            return null;
          }
        },
        activeBgColor () {
          if(this.bgColor){
            const amountToDark = -50;
            return '#' + this.bgColor.replace(/^#/, '').replace(/../g, color => ('0' + Math.min(255, Math.max(0, parseInt(color, 16) + amountToDark)).toString(16)).substr(-2));
          }else{
            return null;
          }
        }
    }
}
</script>