<template>

    <router-link
      class="text-decorator"
      :event="'click'"
      :to="option.navigationPath"
      @click.native.prevent="goToRoute(option.navigationPath)"
    >
      <div class="flip-card">
        <div
          class="flip-card-inner"
          :class="rotateCard ? 'card-rotate' : ''"
        >
          <div class="flip-card-front">
            <div class="pt-2">
              <div class="d-flex justify-content-center">
                <p
                  class="icon d-flex align-items-center justify-content-center"
                >
                  <i :class="option.iconClasses" />
                </p>
              </div>

              <h3>
                {{ optionTitle }}
              </h3>
              <hr class="my-2" />
              <p class="px-4">
                {{ optionDescription }}
              </p>
            </div>
          </div>
          <div class="flip-card-back">
            <div>
              <p class="icon d-flex align-items-center justify-content-center">
                <i :class="option.iconClasses" />
              </p>
            </div>

            <h3 class="mt-4">
              {{ optionTitle }}
            </h3>
            <hr class="my-2" />
            <p class="px-4">
              {{ optionDetail }}
            </p>
          </div>
        </div>
      </div>
    </router-link>

</template>
<script>
import store from '@/store';
import { modulesName } from '@/store';

export default {
  /** Componente común de opciones en tarjeta */
  name: 'SquareOption',
  props: {
    /** Establece las opciones del SquareOption */
    option: {
      type: Object,
      default: () => ({}),
      meta: {
        description: '',
        example: 'this.SquareData',
      },
    },
  },
  data() {
    return {
      hasSelectedStudent: false,
    };
  },
  computed: {
    language(){
        return this.$store.getters[`${modulesName.languageModuleName}/getLanguage`]
    },
    rotateCard() {
      return this.option.rotate == undefined ? true : this.option.rotate;
    },
    optionTitle(){
      return this.language == "es" ? this.option.title : this.option.titleEN;
    },
    optionDescription(){
      return this.language == "es" ? this.option.description : this.option.descriptionEN;
    },
    optionDetail(){
      return this.language == "es" ? this.option.detail : this.option.detailEN;
    }
  },
  methods: {
    goToRoute(route) {
      store.dispatch(`${modulesName.appModuleName}/setSelectedMenuItem`, null);
      this.$router.push(route).catch(() => {});
    },
  },
};
</script>

<style scoped lang="scss">
/**12/16/2019 flip card effect**/
.flip-card {
  background-color: transparent;
  width: 100%;
  height: 240px;
  perspective: 1000px; /* Remove this if you don't want the 3D effect */

  /* Do an horizontal flip when you move the mouse over the flip box container */
  &:hover .card-rotate {
    transform: rotateY(-180deg);
  }
  /* This container is needed to position the front and back side */
  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    /* Position the front and back side */
    .flip-card-front,
    .flip-card-back {
      position: absolute;
      width: 100%;
      height: 240px !important;
      backface-visibility: hidden;
      box-shadow: 0px 1px 1px #00000029;
      padding-top: 15px;
      color: black;
      h3 {
        font: Bold 16px/20px Lato;
      }
      hr {
        width: 40px;
        border-top: 1.5px solid $color-primary;
      }
    }
    /* Style the front side (fallback if image is missing) */
    .flip-card-front {
      background-color: $color-white;

      border: 1px solid $color-label-default;
      border-radius: 5px;
      p.icon {
        height: 100px;
        width: 100px;
        border-radius: 50%;
        margin-bottom: 0;
        background: linear-gradient(-15deg, $color-white, #fdf2e6 125%);
        i {
          color: $color-primary;
          font-size: 35px;
        }
      }

      p {
        color: $color-font-secondary;
        font: 14px/18px Lato;
        letter-spacing: 0;
      }
    }

    /* Style the back side */
    .flip-card-back {
      background-color: #fdf2e6;
      border: 2px solid #ffdbb7;
      border-radius: 5px;
      transform: rotateY(180deg);
      height: auto;
      div {
        height: 40px;
        p.icon {
          position: absolute;
          height: 40px;
          width: 40px;
          border-radius: 50%;
          background-color: $color-primary;
          right: 7px;
          top: 7px;
          i {
            color: $color-white;
            font-size: 16px;
          }
        }
      }

      p {
        font: 14px Lato;
        letter-spacing: 0;
      }
    }
  }
}
/**End of flip card effect**/
</style>
