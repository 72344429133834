<template>
  <b-row
    v-if="options"
  >
    <b-col
      v-for="(option, index) in options"
      :key="option.value"
      class="pr-sm-0"
      cols="6"
      lg="6"
      sm="6"
      xl="3"
    >
      <span
        class="cus-checkbox-container"
        :class="{disableState: disabled}"
      >
        <label class="cus-checkbox-label">
          <input
            :id="[id + '_' + index]"
            :checked="handleSelectedValues(option)"
            :disabled="disabled"
            :name="name"
            type="checkbox"
            :value="option.value"
            @input="handleInput"
          />
          <span />
        </label>
        <label class="ml-2 strong color-black">{{ $t(option.text) }}</label>
      </span>
    </b-col>
  </b-row>
  <!-- <b-form-checkbox-group
    id="checkbox-group-1"
    v-model="recurrency.selectedWeekDays"
    :options="recurrency.options.weekDays"
    name="flavour-1"
  ></b-form-checkbox-group> -->
</template>
<script>
export default {
  name: 'BaseCheckboxGroup',
  props: {
    value: {
      type: Array,
      default () {
        return [];
      }
    },
    id: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default () {
        return [];
      }
    },
    disabled: {
      type: Boolean,
      default () {
        return false;
      }
    }
  },
  data () {
    return {
      selectedOptions: []
    };
  },
  mounted () {
    this.selectedOptions = this.value;
  },
  methods: {
    manageChange () {},
    handleInput (e) {
      if (e.target.checked == true) {
        var checkedValue = this.value.find((a) => a == e.target.value);

        if (checkedValue == null) {
          this.value.push(parseInt(e.target.value));
        }
      } else if (e.target.checked == false) {
        var unCheckedValue = this.value.findIndex((a) => a == e.target.value);

        if (unCheckedValue != null) {
          this.value.splice(unCheckedValue, 1);
        }
      }

      this.$emit('input', this.value);
    },
    handleSelectedValues (option) {
      if (this.value.length == 0) {
        return '';
      }

      var result = this.value.find((a) => a == option.value);

      if (result == undefined) {
        return '';
      }

      return 'checked';
    }
  }
};
</script>
<style lang="scss">
.cus-checkbox-container {
  label {
    position: relative;
  }
  .cus-checkbox-label {
    top: 5px;
  }
  span {
    width: 20px;
    height: 20px;
    border: 1px solid $color-label-default;
    display: inline-block;
    border-radius: 4px;
    transition: all linear 0.3s;
    &:after {
      content: '';
      opacity: 1;
      border-radius: 1px;
      position: absolute;
      top: 1px;
      left: 6px;
      border-bottom: 0.25em solid $color-primary;
      border-right: 0.25em solid $color-primary;
      height: 13px;
      width: 8px;
      transform: rotate(45deg);
      visibility: hidden;
    }
  }
  input {
    display: none;
    &:checked ~ span {
      background: $color-white;
      &:after {
        visibility: visible;
      }
    }
  }
}

.disableState{
  label{
    color: $color-label-default !important;
  }
  input + span:after{
    border-bottom-color: $color-disabled;
    border-right-color: $color-disabled;
  }
}
</style>
