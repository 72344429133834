<template>
  <b-form-group class="c-numericFilter" :label="title ? $t(title) + ':' : ''">
    <div class="c-s-numericFilter">
      <div class="c-iconPlusInput">
        <div class="icon" :class="icon" />
        <input
          ref="input"
          v-model="filteredModel"
          type="number"
          @blur="onInputBlur($event)"
        />
        <i
          v-if="clearable && (value || value === 0)"
          class="fas fa-times fa-lg pointer ml-n2 mt-1"
          @click="onChanged(null)"
        />
      </div>
      <div class="c-increaseDecreaseControls">
        <button class="increaseButton" @click="increase">
          <i class="fas fa-chevron-up" />
        </button>
        <button class="decreaseButton" @click="decrease">
          <i class="fas fa-chevron-down" />
        </button>
      </div>
    </div>
    <error
      v-if="error"
      class="mt-n1"
      :message="errorMsg"
      variant="no-border no-background"
    />
  </b-form-group>
</template>

<script>
import Error from '@/components/basics/error/CustomError.vue';
export default {
  name: 'NumericFilter',
  components: {
    Error
  },
  props: {
    title: {
      type: String,
      default: () => null,
    },
    value: {
      type: [String, Number],
      default: () => null,
    },
    min: {
      type: Number,
      default: 10,
    },
    max: {
      type: Number,
      default: 999,
    },
    icon: {
      type: String,
      default: '',
      meta: {
        description:
          'Go down, to the css section and look for a list of available icons. Use any class identifier found there.',
      },
    },
    measurementUnit: {
      type: String,
      default: '',
      meta: {
        description: 'Example: pt, rem, px, etc.',
      },
    },
    clearable: {
      type: Boolean,
      default: () => false,
      meta: {
        description: 'Makes the clear button visible',
        example: 'true',
      },
    },
    error: {
      type: String,
      default: '',
      meta: {
        description: 'Error state',
      },
    },
    errorMsg: {
      type: String,
      default: () => '',
      meta: {
        description: 'Error Message',
        example: 'This field is required',
      },
    },
  },
  computed: {
    filteredModel: {
      get() {
        return this.cleanValue(this.value);
      },

      set(newValue) {
        let result = null;

        if (!newValue) {
          result = newValue;
        } else {
          result =
            this.measurementUnit && (newValue || newValue === 0)
              ? `${newValue}${this.measurementUnit}`
              : `${newValue}`;
        }

        this.$emit('input', !isNaN(result) && result ? Number(result) : result);
      },
    },
  },
  methods: {
    onInputBlur(event) {
      event.preventDefault();
      this.onChanged(Number(event.target.value));
    },
    onChanged(value) {
      let newValue;
      if (!value && value !== 0) {
        newValue = null;
      } else if (value < this.min) {
        newValue = this.min;
      } else if (value > this.max) {
        newValue = this.max;
      } else {
        newValue = value;
      }

      this.filteredModel = newValue;
    },
    increase() {
      this.onChanged(this.cleanValue(this.value) + 1);
    },
    decrease() {
      this.onChanged(this.cleanValue(this.value) - 1);
    },
    cleanValue(val) {
      if (
        !this.measurementUnit ||
        typeof val === 'number' ||
        (!val && val !== 0)
      )
        return val;
      return Number(val.replace(this.measurementUnit, ''));
    },
  },
  mounted() {},
};
</script>

<style scoped lang="scss">
.c-numericFilter {
  .c-s-numericFilter {
    display: flex;
    align-items: stretch;
    height: 40px;
    .c-iconPlusInput {
      display: flex;
      border-top: 1px solid $color-label-default;
      border-bottom: 1px solid $color-label-default;
      border-left: 1px solid $color-label-default;
      border-radius: 4px 0 0 4px;
      padding: 10px;
      width: 100%;
      background-color: #fff;
      .icon {
        display: flex;
        align-items: center;
        color: $color-primary;
        /*ICONS IDENTIFIER*/
        &.clock {
          &:after {
            content: '\f017';
            font-family: 'Font Awesome 5 Pro', sans-serif;
            font-size: 19px;
            color: $color-primary;
            margin-right: 13px;
          }
        }
      }
      input[type='number'] {
        appearance: none;
        -webkit-appearance: none;
        border: none;
        border-radius: 4px 0 0 4px;
        outline: none;
        width: 100%;
        min-width: 40px;

        /* Chrome, Safari, Edge, Opera */
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        /* Firefox */
        input[type='number'] {
          -moz-appearance: textfield;
        }
      }

      i.fa-times {
        color: $color-font-secondary;
      }
    }
    .c-increaseDecreaseControls {
      display: flex;
      flex-direction: column;
      border: 1px solid $color-label-default;
      border-radius: 0 4px 4px 0;
      button {
        appearance: none;
        -webkit-appearance: none;
        background-color: $color-white;
        border: none;
        line-height: 0;
        outline: none;
        height: 50%;
        transition: background-color 0.3s;

        &:hover {
          background-color: #e5e5e5;
        }

        &:first-child {
          border-bottom: 1px solid $color-label-default;
        }

        i {
          font-size: 12px;
        }
      }
    }
  }
}
</style>
