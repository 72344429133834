import { HubConnectionBuilder } from '@microsoft/signalr';
import { logoutWithRedirect } from '@/core/auth';
import store, { modulesName } from '@/store';

import Vue from 'vue';

import { AUTH, API } from '@/config';

export default {
  install() {
    const uriHub = 'hubs/user';

    // Every component will use hub to access the event bus
    var reconnectPolicy = {
      nextRetryDelayInMilliseconds: (retryContext) => {
        return retryContext.elapsedMilliseconds < 3600000 ? 5000 : null;
      },
    };

    let connection = null;

    async function start() {
      if (!sessionStorage.getItem('user-profile')) {
        setTimeout(() => start(), 5000);
        return;
      }

      // use new Vue instance as an event bus
      const userHub = new Vue();
      // every component will use this.$userHub to access the event bus
      Vue.prototype.$userHub = userHub;

      if (!connection) {
        connection = new HubConnectionBuilder()
          .withUrl(`${API.BASE_URL}/${uriHub}`, {
            accessTokenFactory: () =>
              JSON.parse(
                sessionStorage.getItem(
                  `oidc.user:${AUTH.AUTHORITY}:${AUTH.CLIENT_ID}`,
                ),
              ).access_token,
          })
          .withAutomaticReconnect(reconnectPolicy)
          .build();

        connection.on('performLogout', () => {
          logoutWithRedirect();
        });

        connection.on('setActiveBreak', (breakData) => {
          store.commit(`${modulesName.turnManagerModuleName}/SET_ACTIVE_BREAK`, breakData);
          store.commit(`${modulesName.userModuleName}/SET_ACTIVE_BREAK`, breakData);
        });

        connection.on('setActiveTurn', (turnData) => {
          store.commit(`${modulesName.turnManagerModuleName}/SET_ACTIVE_TURN`, turnData);
          store.commit(`${modulesName.userModuleName}/SET_ACTIVE_TURN`, turnData);
          store.dispatch(
            `${modulesName.turnManagerModuleName}/refreshMetrics`,
            Number(JSON.parse(localStorage.getItem('current-active-location')).locationConfigurationId),
          )
          store.dispatch(`${modulesName.turnManagerModuleName}/searchTurns`);
        });
      }

      try {
        await connection.start();
      } catch (err) {
        setTimeout(() => start(), 5000);
      }
    }

    start();
  },
};
