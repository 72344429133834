<template>
  <validation-observer ref="form" novalidate>
    <div class="row">
      <div class="col-md-12">
        <validation-provider v-slot="{ errors }" rules="required">
          <base-input
            id="currentPassword"
            v-model="model.currentPassword"
            type="password"
            :error="errors[0]"
            :error-msg="$t('requiredField')"
            :label="$t('currentPassword')"
            :tooltip-text="'Sample'"
            :tooltip-icon="'fas fa-edit'"
          />
        </validation-provider>
      </div>
      <div class="col-md-12">
        <validation-provider
          v-slot="{ errors }"
          rules="required"
          vid="newPassword"
        >
          <base-input
            id="newPassword"
            v-model="model.newPassword"
            type="password"
            :error="errors[0]"
            :error-msg="$t('requiredField')"
            :label="$t('newPassword')"
            :show-popover-title="true"
            :show-popover-content="true"
          >
            <template #popoverTitle>
              {{ $t('securityProfile') }}
            </template>
            <template #popoverContent>
              <div class="row px-3" style="max-width: 250px">
                <div class="col-md-12 text-left">
                  {{ $t('minimumCharacters') }}:
                  <b class="float-right">{{ securityProfile.minimalCharacters }}</b>
                </div>
                <div class="col-md-12 text-left">
                  {{ $t('lowLetter') }}:
                  <b class="float-right">{{
                    $t(securityProfile.needLowerCaseLetterFlag ? 'yes' : 'no')
                  }}</b>
                </div>
                <div class="col-md-12 text-left">
                  {{ $t('upLetter') }}:
                  <b class="float-right">{{
                    $t(securityProfile.needUpperCaseLetterFlag ? 'yes' : 'no')
                  }}</b>
                </div>
                <div class="col-md-12 text-left">
                  {{ $t('numberNeeded') }}:
                  <b class="float-right">{{ $t(securityProfile.needNumberFlag ? 'yes' : 'no') }}</b>
                </div>
                <div class="col-md-12 text-left">
                  {{ $t('specialCharacter') }}:
                  <b class="float-right">{{
                    $t(securityProfile.needSpecialCharactersFlag ? 'yes' : 'no')
                  }}</b>
                </div>
              </div>
            </template>
          </base-input>
        </validation-provider>
      </div>
      <div class="col-md-12">
        <validation-provider
          v-slot="{ errors }"
          rules="required|confirmed:newPassword"
        >
          <base-input
            id="confirmPassword"
            v-model="model.confirmPassword"
            type="password"
            :error="errors[0]"
            :error-msg="$t('invalidField')"
            :label="$t('confirmNewPassword')"
          />
        </validation-provider>
      </div>

      <div class="col-md-12 text-right">
        <base-filled-button
          class="mr-0"
          icon-class="far fa-save"
          :on-click="onSubmit"
          :text="$t('save')"
        />
      </div>
    </div>
  </validation-observer>
</template>

<script>
import BaseInput from '@/components/BaseInput.vue';
import {
  getSecurityProfile as _getSecurityProfile,
  changePassword as _changePassword,
} from '@/services/UserService';

export default {
  name: 'ChangePassword',
  components: {
    BaseInput,
  },
  data() {
    return {
      model: {
        currentPassword: null,
        newPassword: null,
        confirmPassword: null,
      },
      securityProfile: {
        minimalCharacters: 0,
        needLowerCaseLetterFlag: false,
        needUpperCaseLetterFlag: false,
        needNumberFlag: false,
        needSpecialCharactersFlag: false,
      },
    };
  },
  mounted() {
    _getSecurityProfile().then((response) => {
      this.securityProfile = response.data;
    });
  },
  computed: {
  },
  methods: {
    async onSubmit() {
      if (!(await this.$refs.form.validate())) return;

      if (this.model.currentPassword === this.model.newPassword)
        return this.ShowErrorToast(this.$t('newPasswordDifferentCurrent'));

      _changePassword(this.model)
        .then(() => {
          this.$emit('close');
          this.ShowSuccessSaveToast();
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
  },
};
</script>
<style lang="scss">
.tooltip-inner {
  max-width: none !important;
}
</style>
