<template>
  <div
    class="c-sideBarNav"
    :class="{ showOnMobile: showSideMenuOnMobile, 'active': !!selectedMenuItem}"
    tabindex="0"
  >
    <div
      class="closeSideMenuBtn"
      @click="$emit('toggledSidebar')"
    >
      X
    </div>

    <img
      class="logoExpanded"
      src="@/assets/icon_turnosPR_expanded@2x.png"
    />

    <img
      class="logoCollapsed"
      src="@/assets/icon_turnosPR_collapsed@2x.png"
    />

    <ul>
      <li
        v-for="(value, key) in menuData"
        :key="key"
        :class="{'active': isMenuItemSelected(value) || isChildrenSelected(value)}"
        @click="$emit('toggledSidebar')"
      >
        <router-link
          class="router-link"
          :to="value.navigationPath"
          :event="''"
          @click.native.prevent="selectMenuItem(value)"
        >
          <i :class="value.iconClasses" />
          <span> {{ language == "es" ? value.title : value.titleEN }}</span>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { modulesName } from '@/store';

export default {
  name: 'SideNavbar',
  data() {
    return {
      selected: this.menuItemId,
    };
  },
  props: {
    menuData: {
      type: Array,
      default: () => [],
    },
    menuItemId: {
      type: Number,
      default: 0,
    },
    showSideMenuOnMobile: {
      type: Boolean,
    },
  },
  computed: {
    ...mapState(modulesName.appModuleName, ['selectedMenuItem']),
    currentPage() {
      return this.$route.path;
    },
    language(){
        return this.$store.getters[`${modulesName.languageModuleName}/getLanguage`]
    },
  },
  methods: {
    selectMenuItem(menuItem) {
      if (!menuItem.children || !menuItem.children.length) {
        this.$store.dispatch(`${modulesName.appModuleName}/setSelectedMenuItem`, null );
        if (this.currentPage == menuItem.navigationPath) return;
        return this.$router.push(menuItem.navigationPath);
      }
      this.$store.dispatch(
        `${modulesName.appModuleName}/setSelectedMenuItem`,
        !this.selectedMenuItem || this.selectedMenuItem.id !== menuItem.id
          ? menuItem
          : null,
      );
    },
    isMenuItemSelected(value) {
      if (this.selectedMenuItem && this.selectedMenuItem.id === value.id) return true;
      else if (value.navigationPath === this.currentPage && !this.selectedMenuItem) return true;
      return false;
    },
    isChildrenSelected(menuItem) {
      return !this.selectedMenuItem && menuItem.children && menuItem.children.length && menuItem.children.some(x => x.navigationPath == this.currentPage)
    }
  },
};
</script>

<style scoped lang="scss">
.c-sideBarNav {
  width: 86px;
  min-height: 100vh;
  flex-shrink: 0;
  padding: 20px 0;
  background: $color-white;
  box-shadow: 2px 2px 20px #e6e6e6;
  z-index: 12;
  transition: width 0.3s, transform 0.3s;
  overflow: hidden;
  @media only screen and (max-width: 768px) {
    position: fixed;
    left: 0;
    top: 0;
    transform: translateX(-100%);
    width: 242px;
    span {
      display: block !important;
    }
    img {
      &.logoExpanded {
        display: block !important;
      }
      &.logoCollapsed {
        display: none;
      }
    }
  }


  &:hover, &.active {
    width: 242px;
    span {
      display: block !important;
    }
    img {
      &.logoExpanded {
        display: block !important;
      }
      &.logoCollapsed {
        display: none;
      }
    }
  }

  .closeSideMenuBtn {
    font-size: 17px;
    color: black;
    text-align: center;
    cursor: pointer;

    @media only screen and (min-width: 768px) {
      display: none;
    }
  }

  &.showOnMobile {
    transform: translateX(0);
    &:hover {
      width: 242px;

      span {
        display: block !important;
      }
      img {
        &.logoExpanded {
          display: block;
        }
        &.logoCollapsed {
          display: none;
        }
      }
    }
  }

  img {
    object-fit: contain;
    margin: 0 auto;
    display: block;

    &.logoExpanded {
      width: 144px;
      height: 86px;
      display: none;
    }
    &.logoCollapsed {
      width: 57px;
      height: 86px;
    }
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      font-family: 'lato-bold', sans-serif;
      font-size: 14px;
      position: relative;

      &:hover {
        &:after {
          content: '';
          width: 2px;
          height: 100%;
          background-color: $color-primary !important;
          position: absolute;
          left: 0;
          top: 0;
        }
      }
      &.active {
        .router-link {
          color: white !important;
          background-color: $color-primary !important;
          i {
            color: white !important;
          }
          &:after {
            content: '';
            width: 2px;
            height: 100%;
            background-color: $color-primary !important;
            position: absolute;
            left: 0;
            top: 0;
          }
        }
      }
      .router-link {
        display: flex;
        align-items: center;
        color: $color-font-primary;
        padding: 15px;
        margin: 0 20px;
        border-radius: 4px;
        margin-top: 5px;
        transition: background-color 0.3s;

        &:hover {
          color: $color-font-primary;
          background-color: #fff2e5;

          i {
            color: $color-primary;
          }
        }

        i {
          width: 15px;
          text-align: center;
          color: $color-primary;
        }

        span {
          margin-left: 15px;
          display: none;
          font-weight: bold;
        }
      }
    }
  }
}
</style>
