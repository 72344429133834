<template>
  <b-container class="my-5 px-5" fluid>
    <b-row>
      <div
        :class="{
          'col-xl-3 col-lg-4 col-md-6 col-sm-6 mt-4': option.navigationPath,
          'col-md-12': !option.navigationPath,
        }"
        v-for="(option, index) in selectedMenuItem.children"
        :key="index"
      >
        <square-option
          v-if="option.navigationPath"
          :key="index"
          :option="option"
        />
        <Collapse v-else :key="index" :title="getLanguage == 'es' ? option.title : option.titleEN">
          <div
            v-for="(subOption, i) in option.children"
            class="col-lg-4 col-md-6 col-sm-6 mt-2"
            :key="i"
          >
            <square-option :key="i" :option="subOption" />
          </div>
        </Collapse>
      </div>
    </b-row>
  </b-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { modulesName } from '@/store';

import SquareOption from './SquareOption.vue';
import Collapse from './Collapse.vue';

export default {
  name: 'MainMenu',
  components: {
    SquareOption,
    Collapse,
  },
  computed: {
    ...mapState(modulesName.appModuleName, ['selectedMenuItem']),
    ...mapGetters(modulesName.languageModuleName, ['getLanguage']),
  },
};
</script>

<style scoped lang="scss"></style>
