<template><div></div></template>

<script>
import Swal from 'sweetalert2';

export default {
  props: {
    confirmButtonText: String,
    cancelButtonText: String,
    title: String,
    content: String,
    timer: Number,
  },
  methods: {
    showAlert(_, __) {
      const swal = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success mt-3 mr-2',
          cancelButton: 'btn btn-danger mt-3',
        },
        buttonsStyling: false,
      });

      swal
        .fire({
          toast: true,
          position: 'top-end',
          title: this.title,
          html: this.content,
          timer: this.timer || 10000,
          timerProgressBar: true,
          showCancelButton: true,
          confirmButtonText: this.confirmButtonText || this.$t('confirm'),
          cancelButtonText: this.cancelButtonText || this.$t('decline'),
          didOpen: () => {},
          willClose: () => {},
        })
        .then((result) => {
          if (result.dismiss === Swal.DismissReason.timer) {
            this.$emit('rejected', _, __);
          }

          if (result.isConfirmed) {
            this.$emit('accepted', _, __);
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            this.$emit('rejected', _, __);
          }
        });
    },
  },
};
</script>
