import { extend } from 'vee-validate';
import { i18n } from '@/plugins/i18n';

extend('allowed_characters', {
  params: ['chars'],
  validate(value, { chars }) {
    const regex = new RegExp(`^[${chars}]*$`);
    return regex.test(value);
  },
  message: () => {
    return i18n.t('validation.allowedCharacters');
  },
});
