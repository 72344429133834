import { HubConnectionBuilder } from '@microsoft/signalr';
import Vue from 'vue';
import store from '@/store';

import Swal from 'sweetalert2';
import { i18n } from '@/plugins/i18n';

import { AUTH, API } from '@/config';
import { modulesName } from '@/store';

export default {
  install() {
    const uriHub = 'hubs/turn';

    let connection = null;

    async function start() {
      if (!sessionStorage.getItem('user-profile')) {
        setTimeout(() => start(), 5000);
        return;
      }

      // Use new Vue instance as an event bus
      const turnHub = new Vue();
      // Every component will use this.$turnHub to access the event bus
      Vue.prototype.$turnHub = turnHub;

      if (!connection) {
        connection = new HubConnectionBuilder()
          .withUrl(`${API.BASE_URL}/${uriHub}`, {
            accessTokenFactory: () =>
              JSON.parse(
                sessionStorage.getItem(
                  `oidc.user:${AUTH.AUTHORITY}:${AUTH.CLIENT_ID}`,
                ),
              ).access_token,
          })
          .build();

        // Manejo de eventos de conexión cerrada
        connection.onclose(async () => {
          await store.dispatch(
            `${modulesName.appModuleName}/setHubLoaded`,
            false,
          );

          try {
            await connection.start().then(async () => {
              await onStart();
            });
          } catch (err) {
            setTimeout(() => start(), 5000);
          }
        });

        // Notifies the client that a turn has been finished.
        connection.on('turnFinished', (turnId, userId) => {
          Vue.prototype.$turnHub.$emit('turnFinished', turnId, userId);
        });

        // Notifies the client that a user has been selected for interaction.
        connection.on('selectedUser', (waitingRoomId, participantType) => {
          Vue.prototype.$turnHub.$emit(
            'selectedUser',
            waitingRoomId,
            participantType,
          );
        });

        connection.on(
          'selectedSupervisorUser',
          (waitingRoomId, participantType) => {
            Vue.prototype.$turnHub.$emit(
              'selectedSupervisorUser',
              waitingRoomId,
              participantType,
            );
          },
        );

        connection.on('selectedSpecialistUser', (waitingRoomId) => {
          Vue.prototype.$turnHub.$emit('selectedSpecialistUser', waitingRoomId);
        });

        // Notifies the client that a user has been found in a waiting room.
        connection.on('userFound', (waitingRoomId, user, participantType) => {
          Vue.prototype.$turnHub.$emit(
            'userFound',
            waitingRoomId,
            user,
            participantType,
          );
        });

        // Notifies the client that no user was found in a waiting room.
        connection.on('userNotFound', (waitingRoomId, participantType) => {
          Vue.prototype.$turnHub.$emit(
            'userNotFound',
            waitingRoomId,
            participantType,
          );
        });

        // Notifies the client that a specialist has been found in a waiting room.
        connection.on('searchingSpecialist', () => {
          Vue.prototype.$turnHub.$emit('searchingSpecialist');
        });

        // Notifies the client that a specialist has been found in a waiting room.
        connection.on('specialistFound', (waitingRoomId, user) => {
          Vue.prototype.$turnHub.$emit('specialistFound', waitingRoomId, user);
        });

        // Notifies the client that no specialist was found in a waiting room.
        connection.on('specialistNotFound', (waitingRoomId) => {
          Vue.prototype.$turnHub.$emit('specialistNotFound', waitingRoomId);
        });

        // Notifies the client that a waiting room session has been completed.
        connection.on('waitingRoomFinished', (waitingRoomId) => {
          Vue.prototype.$turnHub.$emit('waitingRoomFinished', waitingRoomId);
        });

        connection.on('setWaitingRoomStatus', (status) => {
          Vue.prototype.$turnHub.$emit('onWaitingRoomStatusChanged', status);
        });

        connection.on('setWaitingRoomEntry', (status) => {
          Vue.prototype.$turnHub.$emit('onWaitingRoomEntryChanged', status);
        });

        connection.on(
          'turnActionPerformed',
          (actionType, turn, waitingRoom) => {
            Vue.prototype.$turnHub.$emit(
              'turnActionPerformed',
              actionType,
              turn,
              waitingRoom,
            );
          },
        );

        connection.on(
          'userActionRequest',
          (waitingRoomId, userId, userActionRequestType) => {
            Vue.prototype.$turnHub.$emit(
              'onUserActionRequest',
              waitingRoomId,
              userId,
              userActionRequestType,
            );
          },
        );

        connection.on(
          'userActionAccepted',
          (waitingRoomId, userId, userActionRequestType) => {
            Vue.prototype.$turnHub.$emit(
              'onUserActionAccepted',
              waitingRoomId,
              userId,
              userActionRequestType,
            );
          },
        );

        connection.on(
          'userActionRejected',
          (waitingRoomId, userId, userActionRequestType) => {
            Vue.prototype.$turnHub.$emit(
              'onUserActionRejected',
              waitingRoomId,
              userId,
              userActionRequestType,
            );
          },
        );

		connection.on(
          'ChangeParticipantCallState',
          (isPassive, inCall) => {
            Vue.prototype.$turnHub.$emit(
              'onChangeParticipantCallState',
              isPassive,
              inCall
            );
          },
        );
        
        connection.on('NotifyWaitingRoom', (message, notificationType) => {
          if (notificationType == 1 /* FullScreen */) {
            Swal.fire({
              title: 'info',
              toast: false,
              position: 'center',
              timer: 6000,
              text: i18n.t(message),
              icon: 'info',
              confirmButtonColor: '#3085d6',
            });
          } else if (notificationType == 2 /* Toastr */) {
            Swal.fire({
              title: 'info',
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              timer: 6000,
              text: i18n.t(message),
              icon: 'info',
            });
          }
        });

        connection.on('userTyping', (chatId, username) => {
          Vue.prototype.$turnHub.$emit('userTyping', chatId, username);
        });
      }

      connection.on('UpdateCitizenState', (state) => {
        store.commit(
          `${modulesName.turnManagerModuleName}/SET_WAITING_ROOM_CITIZEN_STATE`,
          state,
        );
        store.commit(
          `${modulesName.userModuleName}/SET_WAITING_ROOM_CITIZEN_STATE`,
          state,
        );
      });

      connection.on('Notify', (message, notificationType) => {
        const keys = Object.keys(message.parameters);
        const key1 = keys[0];
        const key2 = keys[1];

        if (notificationType == 1 /* FullScreen */) {
          Swal.fire({
            title: 'info',
            toast: false,
            position: 'center',
            timer: 6000,
            text: i18n.t(message.key, {
              0: message.parameters[key1],
              1: message.parameters[key2],
            }),
            icon: 'info',
            confirmButtonColor: '#3085d6',
          });
        } else if (notificationType == 2 /* Toastr */) {
          Swal.fire({
            title: 'info',
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 6000,
            text: i18n.t(message.key, {
              0: message.parameters[key1],
              1: message.parameters[key2],
            }),
            icon: 'info',
          });
        }
      });

      try {
        await connection.start().then(async () => {
          await onStart();
        });
      } catch (err) {
        setTimeout(() => start(), 5000);
      }
    }

    async function onStart() {
      joinUserGroup();
      settingUpEventListeners();
      await store.dispatch(`${modulesName.appModuleName}/setHubLoaded`, true);
    }

    // Setting up event listeners for the turn hub.
    async function settingUpEventListeners() {
      Vue.prototype.$turnHub?.$on('joinWaitingRoomGroup', (waitingRoomId) => {
        joinWaitingRoomGroup(waitingRoomId);
      });

      Vue.prototype.$turnHub?.$on(
        'requestUserWaitingRoom',
        (userId, waitingRoomId, participantType) => {
          requestUserWaitingRoom(userId, waitingRoomId, participantType);
        },
      );

      Vue.prototype.$turnHub?.$on(
        'requestSpecialistWaitingRoom',
        (userId, waitingRoomId, specialtyId) => {
          requestSpecialistWaitingRoom(userId, waitingRoomId, specialtyId);
        },
      );

      Vue.prototype.$turnHub?.$on(
        'userAcceptedWaitingRoom',
        (waitingRoomId, userId, participantType) => {
          userAcceptedWaitingRoom(waitingRoomId, userId, participantType);
        },
      );

      Vue.prototype.$turnHub?.$on(
        'userNoAcceptedWaitingRoom',
        (waitingRoomId, participantType) => {
          userNoAcceptedWaitingRoom(waitingRoomId, participantType);
        },
      );

      Vue.prototype.$turnHub?.$on(
        'specialistNoAcceptedWaitingRoom',
        (waitingRoomId) => {
          specialistNoAcceptedWaitingRoom(waitingRoomId);
        },
      );

      Vue.prototype.$turnHub?.$on('finishWaitingRoom', (waitingRoomId) => {
        finishWaitingRoom(waitingRoomId);
      });

      Vue.prototype.$turnHub?.$on(
        'userActionRequest',
        (waitingRoomId, userId, userActionRequestType) => {
          onUserActionRequest(waitingRoomId, userId, userActionRequestType);
        },
      );

      Vue.prototype.$turnHub?.$on(
        'userActionAccepted',
        (waitingRoomId, userId, userActionRequestType) => {
          onUserActionAccepted(waitingRoomId, userId, userActionRequestType);
        },
      );

      Vue.prototype.$turnHub?.$on(
        'userActionRejected',
        (waitingRoomId, userId, userActionRequestType) => {
          onUserActionRejected(waitingRoomId, userId, userActionRequestType);
        },
      );

      Vue.prototype.$turnHub?.$on('joinChatGroup', (chatId) => {
        joinChatGroup(chatId);
      });

      Vue.prototype.$turnHub?.$on('leaveChatGroup', (chatId) => {
        leaveChatGroup(chatId);
      });

      Vue.prototype.$turnHub?.$on('userType', (chatId, username) => {
        userType(chatId, username);
      });
    }

    // Join the user to the "UserGroup" SignalR group using the user's ID
    async function joinUserGroup() {
      try {
        // Retrieve user profile from session storage
        const profile = JSON.parse(sessionStorage.getItem('user-profile'));

        // Invoke the 'JoinUserGroup' method on the SignalR hub
        await connection.invoke('JoinUserGroup', profile.userId);
      } catch (err) {
        // If there's an error, retry after a 5-second delay
        setTimeout(() => joinUserGroup(), 5000);
      }
    }

    // Join the user to a SignalR group specific to a waiting room
    async function joinWaitingRoomGroup(waitingRoomId) {
      try {
        // Invoke the 'JoinWaitingRoomGroup' method on the SignalR hub
        await connection.invoke('JoinWaitingRoomGroup', waitingRoomId);
      } catch (err) {
        // If there's an error, retry after a 5-second delay
        setTimeout(() => joinWaitingRoomGroup(waitingRoomId), 5000);
      }
    }

    // Send a request to the SignalR hub to notify that a user is required
    async function requestUserWaitingRoom(
      userId,
      waitingRoomId,
      participantType,
    ) {
      try {
        // Invoke the 'RequestUserWaitingRoom' method on the SignalR hub
        await connection.invoke(
          'RequestUserWaitingRoom',
          userId,
          waitingRoomId,
          participantType,
        );
      } catch (err) {
        // If there's an error, retry after a 5-second delay
        setTimeout(
          () => requestUserWaitingRoom(userId, waitingRoomId, participantType),
          5000,
        );
      }
    }

    // Send a request to the SignalR hub to notify that a specialist is required
    async function requestSpecialistWaitingRoom(
      userId,
      waitingRoomId,
      specialtyId,
    ) {
      try {
        // Invoke the 'RequestSpecilistUserWaitingRoom' method on the SignalR hub
        await connection.invoke(
          'RequestSpecilistUserWaitingRoom',
          userId,
          waitingRoomId,
          specialtyId,
        );
      } catch (err) {
        // If there's an error, retry after a 5-second delay
        setTimeout(
          () =>
            requestSpecialistWaitingRoom(userId, waitingRoomId, specialtyId),
          5000,
        );
      }
    }

    // Notify the SignalR hub that a user has accepted an invitation to enter a waiting room
    async function userAcceptedWaitingRoom(
      waitingRoomId,
      userId,
      participantType,
    ) {
      try {
        // Invoke the 'UserAcceptedWaitingRoom' method on the SignalR hub
        await connection.invoke(
          'UserAcceptedWaitingRoom',
          waitingRoomId,
          userId,
          participantType,
        );
      } catch (err) {
        // If there's an error, retry after a 5-second delay
        setTimeout(
          () => userAcceptedWaitingRoom(waitingRoomId, userId, participantType),
          5000,
        );
      }
    }

    // Notify the SignalR hub that a user has not accepted an invitation to enter a waiting room
    async function userNoAcceptedWaitingRoom(waitingRoomId, participantType) {
      try {
        // Invoke the 'UserNoAcceptedWaitingRoom' method on the SignalR hub
        await connection.invoke(
          'UserNoAcceptedWaitingRoom',
          waitingRoomId,
          participantType,
        );
      } catch (err) {
        // If there's an error, retry after a 5-second delay
        setTimeout(
          () => userAcceptedWaitingRoom(waitingRoomId, participantType),
          5000,
        );
      }
    }
    // Notify the SignalR hub that a specialist has not accepted an invitation to enter a waiting room
    async function specialistNoAcceptedWaitingRoom(waitingRoomId) {
      try {
        // Invoke the 'UserNoAcceptedWaitingRoom' method on the SignalR hub
        await connection.invoke(
          'SpecialistNoAcceptedWaitingRoom',
          waitingRoomId,
        );
      } catch (err) {
        // If there's an error, retry after a 5-second delay
        setTimeout(() => specialistNoAcceptedWaitingRoom(waitingRoomId), 5000);
      }
    }

    // Notify the SignalR hub to finish (close) a specific waiting room
    async function finishWaitingRoom(waitingRoomId) {
      try {
        // Invoke the 'FinishWaitingRoom' method on the SignalR hub
        await connection.invoke('FinishWaitingRoom', waitingRoomId);
      } catch (err) {
        // If there's an error, retry after a 5-second delay
        setTimeout(() => finishWaitingRoom(waitingRoomId), 5000);
      }
    }

    // Join the user to a SignalR group specific to a waiting room
    async function joinChatGroup(chatId) {
      try {
        // Invoke the 'chat' method on the SignalR hub
        await connection.invoke('JoinChatGroup', chatId);
      } catch (err) {
        // If there's an error, retry after a 5-second delay
        setTimeout(() => joinChatGroup(chatId), 5000);
      }
    }

    // Join the user to a SignalR group specific to a waiting room
    async function leaveChatGroup(chatId) {
      try {
        // Invoke the 'chat' method on the SignalR hub
        await connection.invoke('LeaveChatGroup', chatId);
      } catch (err) {
        // If there's an error, retry after a 5-second delay
        setTimeout(() => leaveChatGroup(chatId), 5000);
      }
    }

    async function userType(chatId, username) {
      try {
        // Invoke the 'chat' method on the SignalR hub
        await connection.invoke('UserType', chatId, username);
      } catch (err) {
        // If there's an error, retry after a 5-second delay
        setTimeout(() => userType(chatId), 5000);
      }
    }

    async function onUserActionRequest(
      waitingRoomId,
      userId,
      userActionRequestType,
    ) {
      try {
        await connection.invoke(
          'UserActionRequest',
          waitingRoomId,
          userId,
          userActionRequestType,
        );
      } catch (err) {
        setTimeout(
          () =>
            onUserActionRequest(waitingRoomId, userId, userActionRequestType),
          5000,
        );
      }
    }

    async function onUserActionAccepted(
      waitingRoomId,
      userId,
      userActionRequestType,
    ) {
      try {
        await connection.invoke(
          'UserActionAccepted',
          waitingRoomId,
          userId,
          userActionRequestType,
        );
      } catch (err) {
        setTimeout(
          () =>
            onUserActionAccepted(waitingRoomId, userId, userActionRequestType),
          5000,
        );
      }
    }

    async function onUserActionRejected(
      waitingRoomId,
      userId,
      userActionRequestType,
    ) {
      try {
        await connection.invoke(
          'UserActionRejected',
          waitingRoomId,
          userId,
          userActionRequestType,
        );
      } catch (err) {
        setTimeout(
          () =>
            onUserActionRejected(waitingRoomId, userId, userActionRequestType),
          5000,
        );
      }
    }

    start();
  },
};
