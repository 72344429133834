function getTitle (vm) {
  const { title } = vm.$options;
  if (title) {
    return typeof title === 'function'
      ? title.call(vm)
      : 'Turnos PR - ' + title;
  }
}
export default {
  created () {
    const title = getTitle(this);
    if (title) {
      document.title = title;
    } else {
      document.title = 'Turnos PR';
    }
  }
};
