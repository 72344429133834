import { render, staticRenderFns } from "./Collapse.vue?vue&type=template&id=ec61639c&scoped=true&"
import script from "./Collapse.vue?vue&type=script&lang=js&"
export * from "./Collapse.vue?vue&type=script&lang=js&"
import style0 from "./Collapse.vue?vue&type=style&index=0&id=ec61639c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ec61639c",
  null
  
)

export default component.exports