import Vue from 'vue';
import router from './router';
import VueGtag from 'vue-gtag';
import { i18n } from '@/plugins/i18n';
import App from './App.vue';
import './registerServiceWorker';
// import 'floating-vue/dist/style.css';
import '../public/css/floating-mention.min.css';
import store from './store';
import VueSidebarMenu from 'vue-sidebar-menu';
import 'vue-sidebar-menu/dist/vue-sidebar-menu.css';
import '@/mixins/loggerMixin';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import '../public/css/main.css';

import {
  date,
  datetime,
  capitalize,
  hour,
  daywithnumber,
  price,
  currency,
  uppercase,
  telephone,
} from '@/filters';

import { userMixin } from '@/modules/user/mixins/user.mixin';
import BaseDatetimePicker from '@/components/BaseDatetimePicker';
import FilterSelect from '@/components/FilterSelect';
import FilterColor from '@/components/FilterColor';
import FilterNumeric from '@/components/FilterNumeric';
import BaseConfirmModal from './components/basics/alerts/BaseConfirmModal';
import BaseCheckbox from '@/components/BaseCheckbox';
import BaseCheckboxGroup from '@/components/BaseCheckboxGroup';
import BaseNumericInput from '@/components/BaseNumericInput';
import BaseRadiobuttonGroup from '@/components/BaseRadiobuttonGroup';
import BaseTimePicker from '@/components/BaseTimePicker';
import BaseFilledButton from '@/components/basics/buttons/BaseFilledButton';
import Loading from 'vue-loading-overlay';
import * as VueGoogleMaps from 'vue2-google-maps';
import titleMixin from '@/mixins/titleMixin';
import PortalVue from 'portal-vue';

// Auth
import { startup as authStartup } from '@/core/auth';

// moment.js
import Moment from 'moment';
import { extendMoment } from 'moment-range';

const moment = extendMoment(Moment);

// Config and prototypes
Vue.config.productionTip = false;
Vue.prototype.$moment = moment;
Moment.locale('es');

// Styles
import '@/scss/global.scss';

// Filters
Vue.filter('date', date);
Vue.filter('datetime', datetime);
Vue.filter('capitalize', capitalize);
Vue.filter('hour', hour);
Vue.filter('daywithnumber', daywithnumber);
Vue.filter('price', price);
Vue.filter('currency', currency);
Vue.filter('uppercase', uppercase);
Vue.filter('telephone', telephone);

// Plugins
import './plugins/vee-validate';
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(VueSidebarMenu);
Vue.use(PortalVue);

// Mixins
import '@/mixins/alertMixin';
import '@/mixins/loggerMixin';
Vue.mixin(titleMixin);
Vue.mixin(userMixin);

// Hubs
import userHub from '@/hubs/user-hub';
import turnHub from '@/hubs/turn-hub';

// Directives
Vue.directive('click-outside', {
  bind: function (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      // here I check that click was outside the el and his children
      if (!(el == event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  unbind: function (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent);
  },
});

// Extensions
if (!String.prototype.format) {
  String.prototype.format = function (...args) {
    return this.replace(/(\{\d+\})/g, function (a) {
      return args[+a.substr(1, a.length - 2) || 0];
    });
  };
}

// Custom validations
import './utils/custom-validations';

/** SET GOOGLEMAP */
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS_KEY,
    libraries: 'places',
    region: 'PR',
    language: 'es',
  },
});

// Component general registration
Vue.component('filter-select', FilterSelect);
Vue.component('filter-color', FilterColor);
Vue.component('filter-numeric', FilterNumeric);
Vue.component('base-confirm-modal', BaseConfirmModal);
Vue.component('base-checkbox', BaseCheckbox);
Vue.component('base-checkbox-group', BaseCheckboxGroup);
Vue.component('base-datetime-picker', BaseDatetimePicker);
Vue.component('base-numeric-input', BaseNumericInput);
Vue.component('base-radiobutton-group', BaseRadiobuttonGroup);
Vue.component('base-time-picker', BaseTimePicker);
Vue.component('base-filled-button', BaseFilledButton);
Vue.component('loading', Loading);

authStartup().then((ok) => {
  if (ok) {
    const app = new Vue({
      router,
      store,
      i18n,
      render: (h) => h(App),
    });

    // Hubs
    Vue.use(userHub);
    Vue.use(turnHub);

    app.$mount('#app');
  }
});

// Configuration VueAnalytics
Vue.use(
  VueGtag,
  {
    config: {
      id: 'G-HNS8EB2350',
    },
  },
  router,
);

// Application Insights
import { APPINSIGHTS } from '@/config';

import VueAppInsights from 'vue-application-insights';

if (APPINSIGHTS.KEY !== undefined) {
  Vue.use(VueAppInsights, {
    id: APPINSIGHTS.KEY,
    router,
  });
}